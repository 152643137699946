// Dark theme for sidenav
// Append .sidenav-dark to .sidenav to use

@import "src/assets/scss/variables.scss";


.sidenav-dark {
    background-color: $sidenav-dark-bg;
    color: $dpt-color-text-icon-base-default;

    .sidenav-menu {
        .sidenav-menu-heading {
            color: $sidenav-dark-heading-color;
        }

        .nav-link {
            color: $dpt-color-text-icon-base-default;

            .nav-link-icon {
                color: $dpt-color-text-icon-base-default;
            }

            .sidenav-collapse-arrow {
                color: $sidenav-dark-icon-color;
            }

            &:hover {
                color: $dpt-color-background-base-pressed;
            }

            &.active {
                color: $dpt-color-text-icon-base-default;

                .nav-link-icon {
                    color: $dpt-color-text-icon-base-default;
                }
            }
        }
    }

    .sidenav-menu-nested {
        border-left-color: fade-out($white, 0.85);
    }

    .sidenav-footer {
        background-color: $dpt-color-background-subtle-default;
        width: 100%;
    }
}
