@import "src/assets/scss/variables.scss";

.depict--HoverTitle {
  $dot-margin: 0.6em;
  $background-color: lighten($dpt-color-background-inverse-default, 15%);

  opacity: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: absolute;
  right: 0;
  width: max-content;
  transition: opacity 0.2s;

  .title-text {
    background: $background-color;
    padding: 2px 4px;
    color: $dpt-color-text-icon-inverse-default;
    border-radius: 0.25em;
  }

  .title-triangle {
    width: 0;
    height: 0;

    &--left {
      position: absolute;
      left: -0.5em;
      top: 5px;
      border-bottom: 0.5em solid transparent;
      border-top: 0.5em solid transparent;
      border-right: 0.5em solid $background-color;
      border-left: 0;
    }

    &--bottom {
      border-left: 0.5em solid transparent;
      border-right: 0.5em solid transparent;
      border-top: 0.5em solid $background-color;
      border-bottom: 0;
      margin-right: $dot-margin;
    }
  }
}

.depict--HoverTitle-parent {
  &:hover {
    .depict--HoverTitle {
      opacity: 1;
    }
  }
}
