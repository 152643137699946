@import "src/assets/scss/sb-admin-pro/core/variables/_colors.scss";
@import "src/assets/scss/sb-admin-pro/_variables-colors-overrides.scss";
@import "src/assets/scss/sb-admin-pro/_variables-overrides.scss";
@import "src/assets/scss/variables.scss";

.depict--SortableGridVirtualList {
  max-width: 1300px;
  overflow-y: visible;
  background:
          /* Shadow covers */
          linear-gradient(var(--bs-body-bg) 30%, rgba(255,255,255,0)),
          linear-gradient(rgba(255,255,255,0), var(--bs-body-bg) 70%) 0 100%,

            /* Shadows */
          radial-gradient(farthest-side at 50% 0, rgba(0,0,0,.2), rgba(0,0,0,0)),
          radial-gradient(farthest-side at 50% 100%, rgba(0,0,0,.2), rgba(0,0,0,0)) 0 100%;
  background-repeat: no-repeat;
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;

  /* Opera doesn’t support this in the shorthand */
  background-attachment: local, local, scroll, scroll;
  > div {
    overflow: hidden;
    overflow-y: visible;
  }
}

.depict--GridCard {
  position: relative;
  height: 100%;
  box-shadow: 0 0.15rem 0.25rem $gray-200;
  &__imgWrapper {
    max-height: 100%;
    height: 100%;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    display: flex;
    overflow: hidden;
    background-color: $white;
    padding: 1.25rem;
    box-shadow: inset 0 -0.15rem 0.35rem $gray-200;
    img {
      object-fit: cover;
      max-width: 100%;
      max-height: 100%;
    }
  }
  &__toolsBar {
    position: absolute;
    top: 0.5rem;
    z-index: 1;
  }

  &__modifier_pill {
    //.btn
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    user-select: none;
    border: 1px solid #0000;
    font-weight: 400;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    //.btn
    justify-content: center;
    align-items: center;
    display: inline-flex;

    //.btn-gray-100
    color: $black;
    background-color: $gray-100;
    border-color: $gray-100;

    //.btn-xs
    border-radius: 0.35rem;
    padding: 0.25rem 0.5rem;
    font-size: 0.7rem;
    line-height: 1;



    &__pin {
      background-color: $dpt-color-background-neutral-default;
      border-color: $dpt-color-background-neutral-default;
    }
  }

  &__selected {
    .depict--GridCard__toolsBar {
      .badge {
        background-color: $primary-light !important;
      }
    }
  }

  &__modifier__hide {
    .card-img-top {
      opacity: 0.5;
    }
    .card-body {
      opacity: 0.5;
    }
  }

  &__dropdown {
    .dropdown-menu {
      background-color: $gray-100;
      padding: 0;
      font-size: 0.75rem;
      li {
        padding: 0.25rem 1rem;
      }
    }
  }
  .card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    transition: background-color ease-in-out 500ms;
    border-bottom-left-radius: .35rem;
    border-bottom-right-radius: .35rem;
    .card-text,
    .card-title {
      font-size: 0.75rem;
      text-align: center;
      text-overflow: ellipsis;
      overflow: hidden;
      cursor: text;
    }
  }
}

.depict--GridCard.depict--GridCard__selected .card-body {
  background-color: $dpt-color-background-neutral-default;
  transition: none;
}
