@import "src/assets/scss/variables";
@import "../PairBadge.scss";

$border-border-radius: 18px;
$border-gap: 0.5em;
$border-color: #a0b4ff;
$border-width: 3px;

.depict--GridCardOverlay {
  position: relative;

  .depict--ProductGridCard {
    width: 100%;
    position: absolute;
    box-shadow: 7px 8px 23px -11px rgba(0, 0, 0, 0.5);
  }
}

.depict--DraggingAmountBadge {
  background: black;
  color: white;
  font-size: 1.25rem;
  color: white;
  z-index: 101;
  padding: 10px;
  border-radius: 50%;
  .inner {
    height: 20px;
    width: 20px;
  }
}

.grid-pairs-container {
  .depict--grid-pairs {
    display: grid;

    .depict--ProductGridCard,
    .depict--SortableGridCard {
      height: 100%;
    }

    > div {
      > div {
        margin: 0 !important;
      }
    }

    .depict--SortableGridCard {
      padding-left: calc($border-gap);
      padding-right: calc($border-gap);
    }

    .p-first {
      padding-left: 0;
      margin-left: calc($border-gap) !important;
    }

    .p-last {
      padding-right: 0;
      margin-right: calc($border-gap) !important;
    }

    .depict--SortableGridCard {
      @include PairBadge(
        $border-radius: calc($border-border-radius - 4px) 0 6px 0
      );
      .depict--PairBadge {
        padding-left: 0.6rem;
        padding-top: 0.2rem;
      }

      &:not(&:hover) .depict--PairBadge svg {
        display: none;
      }
    }

    // Pair borders

    .p-first {
      border-radius: $border-border-radius 0 0 $border-border-radius;
      border-left: $border-width solid $border-color;
      border-top: $border-width solid $border-color;
      border-bottom: $border-width solid $border-color;
    }

    .p-middle {
      border-top: $border-width solid $border-color;
      border-bottom: $border-width solid $border-color;
      .depict--PairBadge {
        display: none;
      }
    }

    .p-last {
      border-radius: 0 $border-border-radius $border-border-radius 0;
      border-top: $border-width solid $border-color;
      border-right: $border-width solid $border-color;
      border-bottom: $border-width solid $border-color;
      .depict--PairBadge {
        display: none;
      }
    }
  }
}
