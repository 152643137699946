@import "src/assets/scss/sb-admin-pro/core/variables/_colors.scss";
@import "src/assets/scss/sb-admin-pro/_variables-colors-overrides.scss";
@import "src/assets/scss/sb-admin-pro/_variables-overrides.scss";
@import "src/assets/scss/variables.scss";

.nav-fixed #layoutSidenav #layoutSidenav_nav {
  border-right: 1px solid #CBCBCC;
  .navbar-brand {
    display: none;
    margin-bottom: 2.5rem;
  }
  .sidenav {
    padding-top: calc($topnav-base-height + 1.438rem);
  }
}

.sidenav-dark {
  background: $dpt-color-background-subtle-default;
}

.sidenav-menu {
  .nav-link {
    font-size: 0.875rem;
    .nav-link-icon {
      margin-right: 0;
    }
  }
}

@media (min-width: $hamburger-menu-breakpoint) {
  .nav-fixed #layoutSidenav #layoutSidenav_nav {
    width: $sidenav-width;
    .navbar-brand {
      display: block;
    }
    .sidenav {
      padding-top: 1.438rem;
      .navbar-brand {
        margin-left: 1rem;
        padding-left: 1rem !important;
        img {
          max-width: 90px;
        }
      }
    }
  }
  .nav-fixed #layoutSidenav #layoutSidenav_content {
    padding-left: $sidenav-width;
  }
  .sidenav .nav .nav-link.active {
    background: $dpt-color-background-base-pressed; /* colors not in schema */
    color: $dpt-color-text-icon-base-default; /* colors not in schema */
    .nav-link-icon {
      color: $dpt-color-text-icon-base-default; /* colors not in schema */
    }
  }

  .sidenav .nav .nav-link {
    &:hover {
      color: $dpt-color-text-icon-base-default;
      background-color: $dpt-color-background-base-pressed;
    }
  }

  .sidenav-toggled #layoutSidenav #layoutSidenav_nav {
    transform: translateX($sidenav-width - ($sidenav-width * 2));
  }
  .sidenav-toggled #layoutSidenav #layoutSidenav_content {
    margin-left: $sidenav-width - ($sidenav-width * 2);
  }
}

/**
* Nav
*/

.topnav {
  height: $topnav-base-height;
}

.nav-fixed.depict--BodyNavFixed {
  #layoutSidenav {
    #layoutSidenav_content {
      top: 0;
    }
  }
}

@media (max-width: $hamburger-menu-breakpoint-topdown) {
  .nav-fixed.depict--BodyNavFixed {
    #layoutSidenav {
      #layoutSidenav_content {
        padding-top: $topnav-base-height;
        > main {
          padding-right: 0;
        }
      }
    }
  }
}
