@import "src/assets/scss/sb-admin-pro/core/variables/_colors.scss";
@import "src/assets/scss/sb-admin-pro/_variables-colors-overrides.scss";
@import "src/assets/scss/sb-admin-pro/_variables-overrides.scss";
@import "src/assets/scss/variables.scss";

$footer-height: 4.375rem;

.depict--ConfigurationSearch {
  flex: 1;
  display: flex;
  flex-direction: column;
  input:focus {
    box-shadow: none;
  }
  &__listSelector {
    font-size: 0.75rem;
  }
  &__lists {
    flex: 1;
    color: $black;
  }
  &__list {
    ul {
      margin-bottom: 0;
      li:last-child {
        margin-bottom: $footer-height;
      }
    }
  }
}

@media (max-width: map-get($grid-breakpoints-topdown, "sm")) {
  .depict--ConfigurationSearch {
    height: 1px; //just to have something -- STUPID FLEXBOX
    &__lists {
      align-content: flex-start;
      overflow-y: scroll;
    }
    &__list {
      height: auto !important;
      &:not(:last-child) {
        ul {
          li:last-child {
            margin-bottom: 0.625rem;
          }
        }
      }
    }
  }
}

.depict--ConfigurationSearch__footer {
  height: $footer-height;
  position: absolute;
  bottom: 0;
  background: rgba($black, 0.9);
  width: 100%;
  left: 0;
  padding: 0 1.375rem;
  align-items: center;
}
