@import "src/assets/scss/sb-admin-pro/core/variables/_colors.scss";
@import "src/assets/scss/sb-admin-pro/_variables-colors-overrides.scss";
@import "src/assets/scss/sb-admin-pro/_variables-overrides.scss";
@import "src/assets/scss/variables.scss";


.depict--GridCard__toolsBar {
  div[data-no-dnd="true"] {
    display: flex;
    cursor: pointer;
  }
}

.depict--GridCard__dropdown {
  button[data-bs-toggle="dropdown"] {
    padding: 0;
  }
  .dropdown-menu {
    overflow: hidden;
    li {
      cursor: pointer;
      color: $gray-900;
    }
  }
  &__hide {
    button[data-bs-toggle="dropdown"] {
      color: $black;
      background-color: $gray-400;
      border-color: $gray-400;
    }
  }
  &__pin {
    button[data-bs-toggle="dropdown"] {
      color: $black;
      background-color: $primary-light;
      border-color: $primary-light;
    }
  }
}