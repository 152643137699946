.loadingButton--root {
  position: relative;
  display: inline-block;
  overflow: hidden;
  border-radius: 0.5rem;
  transition: all 0.5s ease-in-out;

  button {
    border-radius: 0.5rem;
    color: white;
    background: black;
    transition: all 0.5s ease-in-out;
  }

  svg {
    path {
      fill: white;
    }
  }

  &.loadingButton--loading {
    cursor: progress;

    button {
      background-color: white;
      color: black;
      opacity: 1;
    }

    //Spin the SVG while loading

    svg {
      animation: spin 3s linear infinite;
      path {
        fill: black;
      }
    }

    @keyframes spin {
      100% {
        transform: rotate(-360deg);
      }
    }
  }

  .loadingButton--progress-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 4px;
    background-color: black;
  }
}

