@import "src/assets/scss/sb-admin-pro/core/variables/_colors.scss";
@import "src/assets/scss/sb-admin-pro/_variables-colors-overrides.scss";
@import "src/assets/scss/sb-admin-pro/_variables-overrides.scss";
@import "src/assets/scss/variables.scss";

.depict--CategoriesCategory {
  .depict--CategoriesCategory__content {
    display: flex;
    flex: 1;
    min-width: 0;
    //background-color: rebeccapurple;
    .depict--CategoriesCategory__content__main {
      //background-color: antiquewhite;
      height: 100%;
      display: flex;
    }
  }
}

.depict--ProductGrid__item {
  height: 100%;
  .depict--DraggableItem__item {
    height: 100%;
  }
}

@media (max-width: map-get($grid-breakpoints-topdown, "lg")) {
  .depict--CategoriesCategory {
    .depict--CategoriesCategory__content {
      flex-direction: column-reverse;
    }
  }
}
