@import "src/assets/scss/sb-admin-pro/core/variables/_colors.scss";
@import "src/assets/scss/sb-admin-pro/_variables-colors-overrides.scss";
@import "src/assets/scss/sb-admin-pro/_variables-overrides.scss";
@import "src/assets/scss/variables.scss";

.depict--CardCollapsible {
  display: flex;
  .collapse.show {
    flex: 1;
    display: flex;
  }
  &.depict--CardCollapsible__show {
    flex: 1;
  }
  &.depict--CardCollapsible__borderRed {
    box-shadow: 0 0 0 1px map-get($palette, "depict-red");
  }
  [data-toggle="collapse"] {
    cursor: default;
    .card-collapsable-arrow {
      cursor: pointer;
      transform: rotate(180deg);
      &::before {
        content: "";
        width: 34px;
        opacity: 0.1;
        height: 34px;
        position: absolute;
        margin-top: -10px;
        margin-left: -10px;
      }
    }
  }
  .card-header[data-toggle="collapse"].collapsed {
    .card-collapsable-arrow {
      transform: rotate(0deg);
    }
  }
}

.depict--CardCollapsible {
  color: $gray-900;
  .card-header {
    padding: 0.875rem 1.5rem;
    font-size: 1rem;
    color: $gray-600 !important;
    line-height: 1;
    background: transparent;
    .depict--CardCollapsible__title {
      display: flex;
      align-items: center;
      flex: 1;
      justify-content: space-between;
    }
  }
  .card-body {
    padding: 1.25rem;
    display: flex;
    flex-direction: column;
    max-width: 100%;
  }
}

.depict--CardCollapsible.depict--CardCollapsible__vertical {
  flex: 1;
  border: 1px solid map-get($palette, "depict-pink");
  &:not(.depict--CardCollapsible__show) {
    overflow: hidden;
  }
  .depict--CardCollapsible__title {
    position: absolute;
    transform: rotate(90deg);
    transform-origin: 0 1rem;
    top: 25px;
    left: 6px;
  }
  .card-header[data-toggle="collapse"] .card-collapsable-arrow {
    transform: rotate(90deg);
  }
  .card-header[data-toggle="collapse"].collapsed {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
    border-width: 0;
    .card-collapsable-arrow {
      transform: rotate(-90deg);
    }
    .depict--CardCollapsible__title {
      white-space: nowrap;
    }
  }
  &.depict--CardCollapsible__show {
    border-width: 0;
    .depict--CardCollapsible__title {
      display: flex;
      transform: none;
      position: relative;
      top: 0;
      left: 0;
    }
  }
}

.depict--lastExpandedPanel {
  .depict--CardCollapsible.depict--CardCollapsible__vertical {
    .card-collapsable-arrow {
      display: none;
    }
  }
}

@media (max-width: map-get($grid-breakpoints-topdown, "sm")) {
  .depict--CardCollapsible {
    height: auto !important;
  }
}
