@import "src/assets/scss/variables.scss";

$pinning-icon-size: 2.5rem;
$hiding-icon-size: 2.5rem;

.depict--ProductGridCard {
  &:not(:hover) {
    .selection-corner:not(.show) {
      display: none;
    }
  }

  .image-container {
    position: relative;

  }
}
