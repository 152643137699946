@import "src/assets/scss/sb-admin-pro/core/variables/_colors.scss";
@import "src/assets/scss/sb-admin-pro/_variables-colors-overrides.scss";
@import "src/assets/scss/sb-admin-pro/_variables-overrides.scss";
@import "src/assets/scss/depict/_variables.scss";

.chart-area {
  height: 12.2rem;
}

.depict--LegendSidebar {
  width: $legend-panel-width;
  height: 100vh;
  z-index: 1038;
  position: fixed;
  top: 0;
  right: 0;
  padding: 1.35rem calc($grid-gutter-width / 2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  ul {
    margin: 0;
    padding: 0;
    max-height: calc(100vh - 40px);
    overflow-y: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none; /* Chrome, Safari and Opera */
    }
    li {
      margin-bottom: 0.625rem;
      cursor: pointer;
      display: flex;
      div {
        font-size: 0.75rem;
        color: black;
        margin-left: 4px;
        max-width: calc(100% - 22px);
        text-overflow: ellipsis;
        overflow: hidden;
        display: inline-block;
        line-height: 1rem;
        text-transform: capitalize;
      }
    }
  }
  .depict--LegendSidebar__icon {
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: $hamburger-menu-breakpoint-topdown) {
  .depict--LegendSidebar {
    position: relative;
    height: auto;
    width: 100%;
    padding: 0;
    margin-bottom: 1rem;
    z-index: 0;
    ul {
      li {
        display: inline-flex;
        margin-right: 1rem;
        div {
          max-width: 100%;
        }
      }
    }
  }
}

.depict--MainContent {
  padding-right: calc($grid-gutter-width / 2);
  padding-left: $grid-gutter-width;
}

.depict--MetricComparison {
  font-size: 14px;
  font-weight: bold;
  color: #7395d3;

  &.negative {
    color: #d47c7c;
  }
}

.sidenav-menu {
  .nav-link {
    font-size: 0.875rem;
    .nav-link-icon {
      margin-right: 0;
    }
  }
}

.nav-fixed.depict--BodyNavFixed {
  #layoutSidenav.depict--ViewDashboard {
    #layoutSidenav_content {
      > main {
        padding-right: $legend-panel-width;
      }
    }
  }
}
