@import "src/assets/scss/sb-admin-pro/core/variables/_colors.scss";
@import "src/assets/scss/sb-admin-pro/_variables-colors-overrides.scss";
@import "src/assets/scss/sb-admin-pro/_variables-overrides.scss";
@import "src/assets/scss/variables.scss";

.depict--Sidebar {
  --sidebar-width: calc(var(--menu-column-width) + 1rem);
  z-index: 1035;
  position: fixed;
  top: 0;
  width: var(--sidebar-width);
  min-width: 250px;
  height: 100vh;
  text-align: left;
  background: $white;
  transition: right 0.15s ease-in-out;
  right: calc(var(--sidebar-width) - var(--sidebar-width) * 2);
  padding: 1.75rem 1rem;
  color: $black;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  &__btnClose {
    position: relative;
    align-self: end;
    width: fit-content;
  }

  &.sidebar-toggled {
    right: 0;
  }
}

@media (max-width: $hamburger-menu-breakpoint-topdown) {
  .depict--Sidebar {
    top: $topnav-base-height;
    height: calc(100vh - $topnav-base-height);
  }
}

@media (max-width: map-get($grid-breakpoints-topdown, "sm")) {
  .depict--Sidebar {
    width: 100%;
  }
}
