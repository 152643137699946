$palette: (
  depict-blue: #7395d3,
  depict-green: #5b9ea6,
  depict-purple: #9b7ce1,
  depict-brown: #868689,
  depict-brown-dark: #333335,
  depict-red: #d47c7c,
  depict-purple-dark: #633eb3,
  depict-blue-light: #4695fb,
  depict-green-light: #809e5d,
  depict-mint: #dbf6fa,
  depict-pink: #e3daf6,
);

/* we only have 5 grays, that's why there are duplicates */
$gray-100: #fafafc;
$gray-200: #eeeef0;
$gray-300: #eeeef0;
$gray-400: #bcbcbe;
$gray-500: #bcbcbe;
$gray-600: #868689;
$gray-700: #868689;
$gray-800: #333335;
$gray-900: #333335;
$black: #0f0f0f;

$sidenav-width: 15.625rem;
$sidebar-width: 20rem;
$sidebar-right-width: 36rem;
$legend-panel-width: 9.00rem;

$topnav-base-height: 2.5rem;
$grid-gutter-width-xxl: 1rem;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1500px,
);

$grid-breakpoints-topdown: ();

@each $key, $value in $grid-breakpoints {
  $map: (
    $key: (
      $value - 1px,
    ),
  );
  $grid-breakpoints-topdown: map-merge($grid-breakpoints-topdown, $map);
}

@each $key, $value in $palette {
  .bg-#{$key} {
    background-color: $value;
  }
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

$hamburger-menu-breakpoint-topdown: map-get($grid-breakpoints-topdown, "xl");
$hamburger-menu-breakpoint: map-get($grid-breakpoints, "xl");

// Text+Icons
$dpt-color-text-icon-base-default: #0f0f0f;
$dpt-color-text-icon-neutral-default: #333335;
$dpt-color-text-icon-subtle-default: #868689;
$dpt-color-text-icon-inverse-default: #ffffff;
$dpt-color-text-icon-accent-default: #3c7cc9;
$dpt-color-text-icon-success-default: #5a8e5a;
$dpt-color-text-icon-warning-default: #8e8359;
$dpt-color-text-icon-danger-default: #92363e;

$dpt-color-text-icon-base-hover: #333335;
$dpt-color-text-icon-neutral-hover: #0f0f0f;
$dpt-color-text-icon-subtle-hover: #333335;
$dpt-color-text-icon-inverse-hover: #f3f3f5;
$dpt-color-text-icon-accent-hover: #485d78;
$dpt-color-text-icon-success-hover: #3c5f3c;
$dpt-color-text-icon-warning-hover: #5f583c;
$dpt-color-text-icon-danger-hover: #61191f;

$dpt-color-text-icon-base-pressed: #000000;
$dpt-color-text-icon-neutral-pressed: #000000;
$dpt-color-text-icon-subtle-pressed: #0f0f0f;
$dpt-color-text-icon-inverse-pressed: #e4e4e5;
$dpt-color-text-icon-accent-pressed: #232a32;
$dpt-color-text-icon-success-pressed: #1e2f1e;
$dpt-color-text-icon-warning-pressed: #2f2c1e;
$dpt-color-text-icon-danger-pressed: #311d1f;

$dpt-color-text-icon-base-disabled: #cbcbcc;
$dpt-color-text-icon-neutral-disabled: #cbcbcc;
$dpt-color-text-icon-subtle-disabled: #cbcbcc;
$dpt-color-text-icon-inverse-disabled: #868689;
$dpt-color-text-icon-accent-disabled: #aed1fb;
$dpt-color-text-icon-success-disabled: #9ee59e;
$dpt-color-text-icon-warning-disabled: #eddb95;
$dpt-color-text-icon-danger-disabled: #f38b93;

// Background colors
$dpt-color-background-base-default: #ffffff;
$dpt-color-background-neutral-default: #e4e4e5;
$dpt-color-background-subtle-default: #f3f3f5;
$dpt-color-background-inverse-default: #0f0f0f;
$dpt-color-background-accent-default: linear-gradient(
  94deg,
  #e4e6fa 100%,
  #f1e7ff 100%
);
$dpt-color-background-success-default: #d5f8d5;
$dpt-color-background-warning-default: #f8f1d5;
$dpt-color-background-danger-default: #fad2d5;

$dpt-color-background-base-hover: #f3f3f5;
$dpt-color-background-neutral-hover: #cbcbcc;
$dpt-color-background-subtle-hover: #e4e4e5;
$dpt-color-background-inverse-hover: #333335;
$dpt-color-background-accent-hover: linear-gradient(
  94deg,
  #c4cfff 100%,
  #e3d9ff 100%
);
$dpt-color-background-success-hover: #9ee59e;
$dpt-color-background-warning-hover: #eddb95;
$dpt-color-background-danger-hover: #f38b93;

$dpt-color-background-base-pressed: #e4e4e5;
$dpt-color-background-neutral-pressed: #cbcbcc;
$dpt-color-background-subtle-pressed: #cbcbcc;
$dpt-color-background-inverse-pressed: #000000;
$dpt-color-background-accent-pressed: linear-gradient(
  94deg,
  #a8baff 100%,
  #d2bdff 100%
);
$dpt-color-background-success-pressed: #78be78;
$dpt-color-background-warning-pressed: #beb077;
$dpt-color-background-danger-pressed: #f3616d;

$dpt-color-background-base-disabled: #fafafc;
$dpt-color-background-neutral-disabled: #fafafc;
$dpt-color-background-subtle-disabled: #fafafc;
$dpt-color-background-inverse-disabled: #cbcbcc;
$dpt-color-background-accent-disabled: linear-gradient(
  94deg,
  #e6e8ff 100%,
  #f9f5ff 100%
);
$dpt-color-background-success-disabled: #eafbea;
$dpt-color-background-warning-disabled: #fbf8ea;
$dpt-color-background-danger-disabled: #fde9eb;

// Map with variables
$background-colors: (
  base: (
    default: $dpt-color-background-base-default,
    hover: $dpt-color-background-base-hover,
    pressed: $dpt-color-background-base-pressed,
    disabled: $dpt-color-background-base-disabled,
  ),
  neutral: (
    default: $dpt-color-background-neutral-default,
    hover: $dpt-color-background-neutral-hover,
    pressed: $dpt-color-background-neutral-pressed,
    disabled: $dpt-color-background-neutral-disabled,
  ),
  subtle: (
    default: $dpt-color-background-subtle-default,
    hover: $dpt-color-background-subtle-hover,
    pressed: $dpt-color-background-subtle-pressed,
    disabled: $dpt-color-background-subtle-disabled,
  ),
  inverse: (
    default: $dpt-color-background-inverse-default,
    hover: $dpt-color-background-inverse-hover,
    pressed: $dpt-color-background-inverse-pressed,
    disabled: $dpt-color-background-inverse-disabled,
  ),
  accent: (
    default: $dpt-color-background-accent-default,
    hover: $dpt-color-background-accent-hover,
    pressed: $dpt-color-background-accent-pressed,
    disabled: $dpt-color-background-accent-disabled,
  ),
  success: (
    default: $dpt-color-background-success-default,
    hover: $dpt-color-background-success-hover,
    pressed: $dpt-color-background-success-pressed,
    disabled: $dpt-color-background-success-disabled,
  ),
  warning: (
    default: $dpt-color-background-warning-default,
    hover: $dpt-color-background-warning-hover,
    pressed: $dpt-color-background-warning-pressed,
    disabled: $dpt-color-background-warning-disabled,
  ),
  danger: (
    default: $dpt-color-background-danger-default,
    hover: $dpt-color-background-danger-hover,
    pressed: $dpt-color-background-danger-pressed,
    disabled: $dpt-color-background-danger-disabled,
  ),
);

// Classes
.dpt-color-background {
  @each $color, $variants in $background-colors {
    @each $state, $value in $variants {
      &-#{$color}-#{$state} {
        background-color: $value;
      }
    }
  }
}

// Border colors
$dpt-color-border-base-default: #0f0f0f;
$dpt-color-border-neutral-default: #868689;
$dpt-color-border-subtle-default: #cbcbcc;
$dpt-color-border-inverse-default: #ffffff;
$dpt-color-border-accent-default: #5087c9;
$dpt-color-border-success-default: #78be78;
$dpt-color-border-warning-default: #beb077;
$dpt-color-border-danger-default: #f3616d;

$dpt-color-border-base-hover: #333335;
$dpt-color-border-neutral-hover: #333335;
$dpt-color-border-subtle-hover: #868689;
$dpt-color-border-inverse-hover: #cbcbcc;
$dpt-color-border-accent-hover: #687d97;
$dpt-color-border-success-hover: #5a8e5a;
$dpt-color-border-warning-hover: #8e8359;
$dpt-color-border-danger-hover: #92363e;

$dpt-color-border-base-pressed: #000000;
$dpt-color-border-neutral-pressed: #0f0f0f;
$dpt-color-border-subtle-pressed: #333335;
$dpt-color-border-inverse-pressed: #e4e4e5;
$dpt-color-border-accent-pressed: #485d78;
$dpt-color-border-success-pressed: #3c5f3c;
$dpt-color-border-warning-pressed: #5f583c;
$dpt-color-border-danger-pressed: #61191f;

$dpt-color-border-base-disabled: #cbcbcc;
$dpt-color-border-neutral-disabled: #cbcbcc;
$dpt-color-border-subtle-disabled: #e4e4e5;
$dpt-color-border-inverse-disabled: #868689;
$dpt-color-border-accent-disabled: #82b1ec;
$dpt-color-border-success-disabled: #9ee59e;
$dpt-color-border-warning-disabled: #eddb95;
$dpt-color-border-danger-disabled: #f38b93;

// Map with variables
$border-colors: (
  base: (
    default: $dpt-color-border-base-default,
    hover: $dpt-color-border-base-hover,
    pressed: $dpt-color-border-base-pressed,
    disabled: $dpt-color-border-base-disabled,
  ),
  neutral: (
    default: $dpt-color-border-neutral-default,
    hover: $dpt-color-border-neutral-hover,
    pressed: $dpt-color-border-neutral-pressed,
    disabled: $dpt-color-border-neutral-disabled,
  ),
  subtle: (
    default: $dpt-color-border-subtle-default,
    hover: $dpt-color-border-subtle-hover,
    pressed: $dpt-color-border-subtle-pressed,
    disabled: $dpt-color-border-subtle-disabled,
  ),
  inverse: (
    default: $dpt-color-border-inverse-default,
    hover: $dpt-color-border-inverse-hover,
    pressed: $dpt-color-border-inverse-pressed,
    disabled: $dpt-color-border-inverse-disabled,
  ),
  accent: (
    default: $dpt-color-border-accent-default,
    hover: $dpt-color-border-accent-hover,
    pressed: $dpt-color-border-accent-pressed,
    disabled: $dpt-color-border-accent-disabled,
  ),
  success: (
    default: $dpt-color-border-success-default,
    hover: $dpt-color-border-success-hover,
    pressed: $dpt-color-border-success-pressed,
    disabled: $dpt-color-border-success-disabled,
  ),
  warning: (
    default: $dpt-color-border-warning-default,
    hover: $dpt-color-border-warning-hover,
    pressed: $dpt-color-border-warning-pressed,
    disabled: $dpt-color-border-warning-disabled,
  ),
  danger: (
    default: $dpt-color-border-danger-default,
    hover: $dpt-color-border-danger-hover,
    pressed: $dpt-color-border-danger-pressed,
    disabled: $dpt-color-border-danger-disabled,
  ),
);

// Classes
.dpt-color-border {
  @each $color, $variants in $border-colors {
    @each $state, $value in $variants {
      &-#{$color}-#{$state} {
        border-color: $value;
      }
    }
  }
}

// Gradients
$dpt-color-gradient-grey-default: linear-gradient(
  180deg,
  #eeeef0 100%,
  #ffffff 100%
);
$dpt-color-gradient-blue-default: linear-gradient(
  180deg,
  #98b0de 100%,
  #ffffff 100%
);
$dpt-color-gradient-purple-default: linear-gradient(
  180deg,
  #e3daf6 100%,
  #ffffff 100%
);
$dpt-color-gradient-light-blue-default: linear-gradient(
  180deg,
  #dbf6fa 100%,
  #ffffff 100%
);
$dpt-color-gradient-yellow-default: linear-gradient(
  180deg,
  #f8f1d5 100%,
  #ffffff 100%
);
$dpt-color-gradient-red-default: linear-gradient(
  180deg,
  #fad2d2 100%,
  #ffffff 100%
);
$dpt-color-gradient-green-default: linear-gradient(
  180deg,
  #d3fad2 100%,
  #ffffff 100%
);

$primary: #000000;
$primary-light: #fff;
$success: $dpt-color-background-success-default;
$secondary: $dpt-color-background-neutral-default;

// Custom
$dpt-pairing-color: #a0b4ff; // The Figma doesn't use a color from the palette for this

// Buttons

// Outline secondary
.btn-outline-secondary {
  color: $dpt-color-text-icon-neutral-default;
  border-color: $dpt-color-border-subtle-default;
}

.btn-outline-secondary:hover {
  color: $dpt-color-text-icon-neutral-hover;
  border-color: $dpt-color-border-subtle-hover;
  background: $dpt-color-background-base-hover;
}

.btn-outline-secondary:active {
  color: $dpt-color-text-icon-neutral-pressed;
  border-color: $dpt-color-border-subtle-pressed;
  background: $dpt-color-background-base-pressed;
}

.btn-outline-secondary:disabled {
  color: $dpt-color-text-icon-neutral-disabled;
  border-color: $dpt-color-border-subtle-disabled;
}
