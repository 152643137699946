@import "src/assets/scss/sb-admin-pro/core/variables/_colors.scss";
@import "src/assets/scss/sb-admin-pro/_variables-colors-overrides.scss";
@import "src/assets/scss/sb-admin-pro/_variables-overrides.scss";
@import "src/assets/scss/variables.scss";

#layoutAuthentication {
  background: $black;
  .card {
    background: transparent;
    .card-header {
      background: transparent;
    }
  }
}

.dpct-LoginModal {
  .logo {
    max-width: 200px;
  }
}

#layoutAuthentication {
  form {
    .input-group {
      input {
        margin-right: -1px;
      }
      &:focus {
        outline: none;
      }
    }
    input {
      &:focus {
        box-shadow: none;
        background: $primary-light;
      }
      &:valid {
        background: map-get($palette, "depict-mint");
        & + .input-group-text {
          background: map-get($palette, "depict-mint");
        }
      }
    }
    .depict-LoadingButtonBackground {
      background: map-get($palette, "depict-mint") !important;
    }
  }
  form.depict--IsInvalid {
    .form-control,
    .input-group-text {
      background-color: map-get($palette, "depict-pink") !important;
    }
  }
}
