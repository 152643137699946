// Override Bootstrap typography variables

$font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
  "Segoe UI Symbol", "Noto Color Emoji";

$headings-font-weight: 500;

$font-family-base: $font-family-sans-serif;

$font-weight-normal: 400;
$font-weight-bold: 500;

$font-size-root: null;
$font-size-base: 1rem; // Assumes the browser default, typically `16px`
$font-size-lg: $font-size-base * 1.25;
$font-size-sm: $font-size-base * 0.875;

$line-height-xs: 1;

$h1-font-size: $font-size-base * 1.5;
$h2-font-size: $font-size-base * 1.4;
$h3-font-size: $font-size-base * 1.3;
$h4-font-size: $font-size-base * 1.2;
$h5-font-size: $font-size-base * 1.1;
$h6-font-size: $font-size-base;

$small-font-size: 0.875em;

$lead-font-size: $font-size-base * 1.1;
$lead-font-weight: 400;

$text-muted: $gray-500;

$display1-size: 5.5rem;
$display2-size: 4.5rem;
$display3-size: 3.5rem;
$display4-size: 2.5rem;

$link-decoration: none;
$link-hover-decoration: underline;
