.accordion-header button {
  background-color: inherit !important;
  box-shadow: none !important;
  padding: 0;

  &:focus {
    box-shadow: none !important;  
  }
}

.accordion-item {
  border: none !important;
}

.accordion-item .accordion-header {
  margin-left: 0.3rem;
}