@import "src/assets/scss/sb-admin-pro/core/variables/_colors.scss";
@import "src/assets/scss/sb-admin-pro/_variables-colors-overrides.scss";
@import "src/assets/scss/sb-admin-pro/_variables-overrides.scss";
@import "src/assets/scss/variables.scss";

.depict--Popover {
  .popover-header {
    padding: 1.875rem 1.875rem 0.438rem 1.875rem;
    background-color: transparent;
    font-size: 0.875rem;
    color: $gray-600;
    border-bottom: 0;
  }
  .popover-body {
    padding: 0.438rem 1.875rem 1.875rem 1.875rem;
  }
  .btn-close {
    background-size: 0.75rem;
    margin: 0;
    padding: 0.625rem;
    position: absolute;
    top: 0;
    right: 0;
  }
}

@each $name, $color in $palette {
  .depict--Popover.depict--Popover__#{$name} {
    border-color: $color;
    &.bs-popover-end > .popover-arrow:before,
    &.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:before {
      border-right-color: $color;
    }
    &.bs-popover-start > .popover-arrow:before,
    &.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:before {
      border-left-color: $color;
    }
    &.bs-popover-top > .popover-arrow:before,
    &.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:before {
      border-top-color: $color;
    }
    &.bs-popover-bottom > .popover-arrow:before,
    &.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:before {
      border-bottom-color: $color;
    }
  }
}
