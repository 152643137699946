@import "src/assets/scss/sb-admin-pro/core/variables/_colors.scss";
@import "src/assets/scss/sb-admin-pro/_variables-colors-overrides.scss";
@import "src/assets/scss/sb-admin-pro/_variables-overrides.scss";
@import "src/assets/scss/variables.scss";

.depict--ConfigurationSettings {
  font-size: 0.75rem;
  > div:not(:last-child) {
    border-color: $gray-200;
    border-style: solid;
    border-width: 0;
    border-right-width: 1px;
  }
  &__title {
    font-weight: 500;
  }
  &__subtitle {
    color: $gray-600;
    font-size: 0.688rem;
  }
  &__form {
    .form-check {
      min-height: 0;
      display: flex;
      //margin-right: 2rem;
    }
    .form-check.form-switch {
      padding-left: 2.5rem;
      .form-check-input,
      .form-input-spinner {
        width: 2rem;
        height: 1rem;
        margin-left: -2.5rem;
        margin-top: 1px;
      }
    }
    .depict--datepickerWrapper {
      .input-group {
        background: $gray-100; //$primary-light;
        flex-wrap: nowrap;
        .input-group-text {
          background: transparent;
        }
      }
      .react-datepicker-wrapper {
        padding: 5px 10px;
        width: auto;
        min-width: 0;
        display: flex;
        flex: 1;
        input {
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          border: 0;
          background: transparent;
          width: 100%;
          &:focus {
            outline: none;
          }
        }
      }
      .react-datepicker {
        display: flex;
        flex-direction: column-reverse;
        .react-datepicker__navigation {
          top: 22px;
        }
      }
      &.depict--datepickerWrapper__disabled {
        opacity: 0.3;
        .input-group {
          background: $gray-300;
        }
      }
      &.depict--datepickerWrapper__hasValue {
        .input-group {
          background: $primary-light;
        }
      }
    }
    .form-check:not(.form-switch) {
      .form-check-input {
        width: 1rem;
        height: 1rem;
        margin-top: 1px;
        margin-right: 0.5rem;
        min-width: 1rem !important;
      }
      label {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .form-check-input[type="radio"] {
        + .form-check-label {
          font-size: 0.6875rem;
        }
      }
    }
  }
}

@media (max-width: map-get($grid-breakpoints-topdown, "xl")) {
  .depict--ConfigurationSettings {
    &__form {
      .depict--datepickerWrapper {
        margin-top: 0.75rem;
      }
      .form-check:not(.form-switch) {
        display: flex;
        margin-right: 0;
        .form-check-input {
          flex-basis: 1rem;
          min-width: 1rem;
          max-width: 1rem;
        }
        &.form-check-inline {
          margin-bottom: 0.25rem !important;
        }
      }
    }
  }
}

@media (max-width: map-get($grid-breakpoints-topdown, "md")) {
  .depict--ConfigurationSettings {
    > div:not(:last-child) {
      border-right-width: 0;
      border-bottom-width: 1px;
      margin-bottom: 1rem;
      padding-bottom: 1rem;
    }
  }
}

.depict--Surface {
  white-space: pre-wrap;
  text-overflow: unset;
  overflow: visible;
  .depict--Surface__icon {
    color: $gray-500;
  }
  .depict--Surface__name {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
