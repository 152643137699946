@import "src/assets/scss/sb-admin-pro/core/variables/_colors.scss";
@import "src/assets/scss/sb-admin-pro/_variables-colors-overrides.scss";
@import "src/assets/scss/sb-admin-pro/_variables-overrides.scss";
@import "src/assets/scss/variables.scss";

.depict--cardMetric {
  h5 {
    font-size: 1rem;
    color: $gray-600;
    + p {
      font-size: 1.5rem;
      line-height: 1.5rem;
      color: $black;
      font-weight: bold;
      margin-bottom: 0;
    }
  }
  .depict--cardColumns {
    display: flex !important;
  }
  .depict--cardMetricRight {
    flex: 1;
    min-width: 1px;
  }
  .depict--cardMetricLeft {
    margin-right: 45px;
    .depict--cardMetricLeftInner {
      flex-direction: column !important;
      display: flex !important;
      height: 100%;
    }
  }
  .depict--cardMetricIconWrapper {
    position: absolute;
    top: 1.35rem;
    left: 1.35rem;
    line-height: 0;
  }
}

@media (max-width: map-get($grid-breakpoints-topdown, "sm")) {
  .depict--cardMetric {
    .card-body {
      padding: 1rem;
    }
    .depict--cardMetricIconWrapper {
      right: 1.35rem;
      left: auto;
    }
    .depict--cardColumns {
      flex-direction: column;
    }
  }
}
