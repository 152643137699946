.depict-gradient {
    background: linear-gradient(180deg, #E2E0F3 0%, #D9E0F1 100%);
    transform: rotate(6.86deg);
}

.rotate {
  animation: rotation 8s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
