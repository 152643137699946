.depict--Alerts {
  position: fixed;

  right: 0;
  top: 0;
  padding: 1rem;
  overflow: scroll;
  z-index: 1039;
  max-height: 100%;
  pointer-events: all;
  .toast {
    background-color: #fff;
    box-shadow: none;
    border-top-color: #0f0f0f1a !important;
    border-right-color: #0f0f0f1a !important;
    border-bottom-color: #0f0f0f1a !important;
    border-left-width: 2px;
  }
}
