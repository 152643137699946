// _typography.scss
$headline-100-light: (
  font-family: "Inter",
  font-weight: 100,
  font-size: 2.25rem,
  line-height: 2.75rem,
  letter-spacing: 0.01em,
  color: #000000,
);

$headline-100-heavy: (
  font-family: "Inter",
  font-weight: 900,
  font-size: 2.25rem,
  line-height: 2.75rem,
  letter-spacing: 0.01em,
  color: #000000,
);

$headline-200-light: (
  font-family: "Inter",
  font-weight: 100,
  font-size: 2rem,
  line-height: 2.5rem,
  letter-spacing: 0.01em,
  color: #000000,
);

$headline-200-heavy: (
  font-family: "Inter",
  font-weight: 900,
  font-size: 2rem,
  line-height: 2.5rem,
  letter-spacing: 0.01em,
  color: #000000,
);

$headline-300-light: (
  font-family: "Inter",
  font-weight: 100,
  font-size: 1.75rem,
  line-height: 2.25rem,
  letter-spacing: 0.01em,
  color: #000000,
);

$headline-300-heavy: (
  font-family: "Inter",
  font-weight: 900,
  font-size: 1.75rem,
  line-height: 2.25rem,
  letter-spacing: 0.01em,
  color: #000000,
);

// Add other text styles as needed

@mixin apply-text-style($style-map) {
  font-family: map-get($style-map, font-family);
  font-weight: map-get($style-map, font-weight);
  font-size: map-get($style-map, font-size);
  line-height: map-get($style-map, line-height);
  letter-spacing: map-get($style-map, letter-spacing);
  color: map-get($style-map, color);
}
