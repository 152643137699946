.depict--BoostAndBuryPreview__cards {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50%;
  width: 100%;
  position: relative;
  overflow: hidden;
  // Mix of padding and margin is to keep height of the cards a true 50% while space from edges are even
  margin-top: 2rem;
  padding-left: 2rem;
  margin-bottom: 1rem;

  // Fade out images once we are at the end of the row using pseudo element
  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 6rem;
    height: 100%;
    background: linear-gradient(270deg, #E4E4E5 9.52%, rgba(228, 228, 229, 0) 100%);
    z-index: 110;
  }

  &.hide-fade {
    &:after {
      display: none;
    }
  }
}

.depict--BoostAndBuryPreview__card {
  position: relative;
  width: 100%;
  height: 100%;
  background: white;
  padding: 0.5rem;
  border-radius: 1rem;
  overflow: hidden;
  max-width: 150px;
  max-height: 260px;

  &.--square {
    max-width: 180px;
    max-height: 180px;
  }

  img {
    position: absolute;
    width: calc(100% - 1rem);
    height: calc(100% - 1rem);
    object-fit: cover;
    border-radius: 0.5rem;
  }

  .depict--placeholder {
    border-radius: 0.5rem;
  }

  .depict--BoostAndBuryPreview__badges {
    position: absolute;
    bottom: 0.5rem;
    left: 0.5rem;
    z-index: 100;
    .depict--BoostAndBuryPreview__icon {
      width: fit-content;
      background: white;
      border-radius: 0 14px 0 0;
      padding: 0.5rem 0.5rem 0.25rem 0.25rem;
      display: flex;
      align-items: center;
      position: relative;
      z-index: 110;

      // Top left inverse border
      &:before {
        z-index: 105;
        content: "";
        position: absolute;
        background-color: transparent;
        top: -50px;
        left:0;
        height: 50px;
        width: 0.5rem;
        border-bottom-left-radius: 1rem;
        box-shadow: 0px 12px 0 0 white;
      }

      // Bottom right inverse border
      &:after {
        z-index: 105;
        content: "";
        position: absolute;
        background-color: transparent;
        bottom: 0;
        right: -0.5rem;
        height: 25px;
        width: 0.5rem;
        border-bottom-left-radius: 1rem;
        box-shadow: 0 12px 0 0 white;
      }

      svg {
        z-index: 110;
        background: white;
      }
    }

    .depict--BoostAndBuryPreview__badge {
      width: fit-content;
      background: white;
      border-radius: 0 14px 0 0;
      padding: 0.25rem 0.25rem 0 0.25rem;
      display: flex;
      align-items: center;
      position: relative;
      z-index: 110;

      // Top left inverse border
      &:before {
        z-index: 105;
        content: "";
        position: absolute;
        background-color: transparent;
        top: -50px;
        left:0;
        height: 50px;
        width: 0.5rem;
        border-bottom-left-radius: 1rem;
        box-shadow: 0px 12px 0 0 white;
      }

      // Bottom right inverse border
      &:after {
        z-index: 105;
        content: "";
        position: absolute;
        background-color: transparent;
        bottom: 0;
        right: -0.5rem;
        height: 25px;
        width: 0.5rem;
        border-bottom-left-radius: 1rem;
        box-shadow: 0 12px 0 0 white;
      }

      p {
        padding: 0.25rem 0.5rem;
        border-radius: 100px;
        z-index: 110;
        font-weight: 500;
        font-size: 0.75rem;
        margin: 0;
        line-height: 0.7rem;
      }
    }
  }
}