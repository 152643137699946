@import "src/assets/scss/sb-admin-pro/core/variables/_colors.scss";
@import "src/assets/scss/sb-admin-pro/_variables-colors-overrides.scss";
@import "src/assets/scss/sb-admin-pro/_variables-overrides.scss";
@import "src/assets/scss/depict/_variables.scss";

div[class^="depict--ViewConfigure"] {
  main {
    flex: 1;
    display: flex;
  }
  .depict--MainContent {
    display: flex;
    flex-direction: column;
    .depict--Content {
      flex: 1;
      .depict--ConfigureConfigurations {
        min-height: 100%;
        display: flex;
        flex-direction: column;
        max-width: 16.66vw;
      }
      .depict--ConfigureConfiguration {
        display: flex;
        flex-direction: column;
        height: 100%;
        &__relationship {
          flex: 1;
          &__from {
            height: 100%;
          }
          &__to {
            height: 100%;
          }
        }
        .depict--CardCollapsible__title {
          font-size: 0.75rem;
          font-weight: 500;
          color: $gray-900;
        }
        .depict--ItemsList {
          z-index: 2;
        }
      }
    }
  }
}

@media (max-width: map-get($grid-breakpoints-topdown, "lg")) {
  div[class^="depict--ViewConfigure"] {
    .depict--MainContent {
      .depict--Content {
        flex-direction: column;
        .depict--ConfigureConfigurations {
          max-width: 100%;
          min-height: 50px;
          .depict--ListConfigurations {
            max-height: 400px;
          }
        }
      }
    }
  }
}

.depict--ConfigurationsToolbar {
  padding: 0.625rem;
  input[type="text"] {
    background-color: $gray-200;
    &:focus {
      box-shadow: none;
    }
    + .input-group-text {
      background-color: $gray-200;
      &:focus {
        box-shadow: none;
      }
    }
  }
}

.depict--ConfigureConfigurations {
  .card-body {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;
  }
}

.depict--ConfigurationsFooter {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

div[class^="depict--ViewConfigure"],
div[class^="depict--View-Configure"] {
  .depict--MerchantsSelect {
    margin-right: -0.25rem;
  }
  .depict--marketSelector {
    min-width: 200px;
    max-width: 200px;
    align-self: center;
    height: 100%;
    display: flex;
    > div {
      background-color: #0000 !important;
      font-size: 1.313rem;
      width: 100%;
    }
    .form-label {
      display: none;
    }
    select {
      align-self: center;
    }
    button {
      max-height: 30px;
      align-self: center;
      background-color: transparent !important;
      color: $black;
      font-size: 1.313rem;
      cursor: default;
      padding: 0;
      padding-left: 0.25rem;
      text-align: left;
    }
    .form-select {
      background-color: transparent !important;
      color: $black;
      font-size: 1.313rem;
      padding-left: 0.25rem;
      &:focus {
        box-shadow: none;
      }
    }
  }
  .depict--Header {
    .depict--Separator {
      display: flex;
      align-items: center;
      > div {
        font-size: 1.5rem;
        display: flex;
        color: $black;
      }
    }
  }
}

@media (max-width: map-get($grid-breakpoints-topdown, "md")) {
  div[class^="depict--ViewConfigure"] {
    .depict--marketSelector {
      max-width: 100%;
      min-width: unset;
    }
  }
}

.depict--ConfigurationSearch__listSelector .form-check-input:checked {
  background-color: $blue;
  border-color: $blue;
}

