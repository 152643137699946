@import "src/assets/scss/sb-admin-pro/core/variables/_colors.scss";
@import "src/assets/scss/sb-admin-pro/_variables-colors-overrides.scss";
@import "src/assets/scss/sb-admin-pro/_variables-overrides.scss";
@import "src/assets/scss/variables.scss";

.depict--ConfigurationToolbar {
  display: flex;
  &__configurationName {
    font-weight: 500;
    font-size: 1.125rem;
    flex: 1;
  }
}

.depict--ConfigurationToolbar__configurationName {
  display: flex;
  form {
    width: 100%;
  }
  input[type="text"] {
    padding: 0;
    font-size: 1.125rem;
    min-height: 0;
    height: 100%;
    background: transparent;
    color: $gray-600;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    &:focus {
      box-shadow: none;
    }
  }
}

@media (max-width: map-get($grid-breakpoints-topdown, "md")) {
  .depict--ConfigurationToolbar {
    flex-direction: column;
    &__configurationName {
      margin-bottom: 1rem;
    }
  }
}

.depict--ConfigurationToolbar__actions {
  display: flex;
  align-items: center;
  > * {
    order: 2;
  }
}

@media (max-width: map-get($grid-breakpoints-topdown, "md")) {
  .depict--ConfigurationToolbar__actions {
    flex-wrap: wrap;
    justify-content: flex-end;
    .depict--Label__restore {
      order: 1;
      flex-basis: 100%;
      margin-bottom: 1rem;
      text-align: right;
    }
  }
}
