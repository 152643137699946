@import "src/assets/scss/variables";
@import "../PairBadge.scss";

.depict--Paired {
  @include PairBadge($border-radius: 0px 0px 6px 6px);
  .depict--PairBadge {
    transform: translate(-50%);
    left: 50%;
  }
  &:not(&:hover) .depict--PairBadge svg {
    display: none;
  }
}

.depict--Paired > .depict-row {
  border-top: 0 !important;
  border-left: 3px solid $dpt-pairing-color !important;
  border-right: 3px solid $dpt-pairing-color !important;
  border-radius: 0 !important;
}

.depict--Paired > .depict-row.index_0 {
  border-top: 3px solid $dpt-pairing-color !important;
  border-radius: 0.35rem 0.35rem 0 0 !important;
}

.depict--Paired > .depict-row:last-of-type {
  border-bottom: 3px solid $dpt-pairing-color !important;
  border-radius: 0 0 0.35rem 0.35rem !important;
}

.depict--ListCardOverlay {
  cursor: grabbing;
}

.depict--ListCardOverlay:not(.depict--Paired) > .depict-row {
  border: 2.5px solid $dpt-color-border-accent-default !important;
  border-radius: 0.35rem;
}

.depict--DragIndicator {
  padding: 1.9rem 0;
}

