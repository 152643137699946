@import "src/assets/scss/sb-admin-pro/core/variables/_colors.scss";
@import "src/assets/scss/sb-admin-pro/_variables-colors-overrides.scss";
@import "src/assets/scss/sb-admin-pro/_variables-overrides.scss";
@import "src/assets/scss/variables.scss";

.splash-screen {
  background-color: $black;
  display: flex;
  height: 100%;
  position: fixed;
  width: 100%;
  justify-content: center;
  align-items: center;
  .spinner-border {
    width: 4rem;
    height: 4rem;
    border-width: 0.5em;
  }
}
