@import "src/assets/scss/variables.scss";

.depict--CategoryRankingOrder__option {
  border-radius: 0.5rem;
  max-width: 450px;
  position: relative;
  overflow: hidden;
  border: 2px solid $dpt-color-border-subtle-default;

  .depict--CategoryRankingOrder__badges {
    position: absolute;
    bottom: 0.5rem;
    top: 0;
    right:0;
    z-index: 100;
    .depict--CategoryRankingOrder__icon {
      width: fit-content;
      background: black;
      border-radius: 0 1px 0 14px;
      padding: 0.1rem 0.1rem 0.25rem 0.25rem;
      display: flex;
      align-items: center;
      position: relative;
      z-index: 110;

      // Top left inverse border
      &:before {
        z-index: 105;
        content: "";
        position: absolute;
        background-color: transparent;
        top: 0;
        left: -0.5rem;
        height: 50px;
        width: 0.5rem;
        border-top-right-radius: 1rem;
        box-shadow: 0px -12px 0 0 black;
      }

      // Bottom right inverse border
      &:after {
        z-index: 105;
        content: "";
        position: absolute;
        background-color: transparent;
        bottom: -25px;
        right: 0;
        height: 25px;
        width: 0.5rem;
        border-top-right-radius: 1rem;
        box-shadow: 0 -12px 0 0 black;
      }

      svg {
        z-index: 110;
        background: black;
      }
    }
  }

  &:hover {
    border-color: $dpt-color-border-accent-default;
  }
}