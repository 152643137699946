$modal-min-width: min(1200px, 100%);

.add-products-modal {
  min-width: $modal-min-width;
}

.add-products-modal-content {
  min-width: $modal-min-width;
  max-height: 90vh;

  .depict--FilterContainer .depict--Filters {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  .depict--FilterContainer .depict--Filters::-webkit-scrollbar {
      display: none; /* Chrome, Safari, Opera*/
  }
}

.depict.plp .depict-slider button.major, .depict.plp .depict-slider button.minor {
  padding: 0.25rem 0.5rem;
  font-size: 1rem;
  flex-direction: row-reverse;
  white-space: nowrap;

  svg {
    width: 0.75rem;
    height: 0.75rem;
    margin-left: 0.5rem;
  }
}
