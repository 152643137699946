@import "src/assets/scss/sb-admin-pro/core/variables/_colors.scss";
@import "src/assets/scss/sb-admin-pro/_variables-colors-overrides.scss";
@import "src/assets/scss/sb-admin-pro/_variables-overrides.scss";
@import "src/assets/scss/variables.scss";

.depict--SidebarOverlayRight {
  z-index: 2147483002; // place above intercom icon
  position: fixed;
  top: 0;
  width: $sidebar-right-width;
  height: 100vh;
  text-align: left;
  background: $black;
  transition: right 0.15s ease-in-out;
  right: calc($sidebar-right-width - $sidebar-right-width * 2);
  padding: 1.25rem 1.375rem;
  color: #fff;
  display: flex;
  flex-direction: column;
  &__btnClose {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.sideoverlayright-toggled {
  .depict--SidebarOverlayRight {
    right: 0;
    &__btnClose:after {
      content: "";
      width: 100%;
      height: 100%;
      position: fixed;
      background-color: red;
      right: 0;
      top: 0;
      opacity: 0.5;
      background: $black;
      transition: opacity 0.3s ease-in-out;
      z-index: -1;
    }
  }
}

@media (max-width: $hamburger-menu-breakpoint-topdown) {
  .depict--SidebarOverlayRight {
    top: $topnav-base-height;
    height: calc(100vh - $topnav-base-height);
  }
}

@media (max-width: map-get($grid-breakpoints-topdown, "sm")) {
  .depict--SidebarOverlayRight {
    width: 100%;
  }
}
