@import "src/assets/scss/variables.scss";

.depict--CurvyCorner {
  $margin-amount: 0;
  $triangle-size: var(--curvy-corner-triangle-size, 1em);
  $border-radius: var(--curvy-corner-border-radius, 0.75em);

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: absolute;
  bottom: 0;
  right: 0;

  .inner-container {
    min-height: 4em;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .content-container {
    position: relative;
    padding: $margin-amount;
    background: $dpt-color-background-base-default;
  }

  &.bottom-right .content-container {
    border-top-left-radius: $border-radius;
  }
  &.top-right .content-container {
    border-bottom-left-radius: $border-radius;
  }
  &.bottom-left .content-container {
    border-top-right-radius: $border-radius;
  }
  &.top-left .content-container {
    border-bottom-right-radius: $border-radius;
  }

  &.bottom-right .smooth-triangle {
    &.triangle-1 {
      top: calc($triangle-size * -2);
      right: 0;
    }
    &.triangle-2 {
      bottom: 0;
      left: calc($triangle-size * -2);
    }
  }

  &.top-right .smooth-triangle {
    &::before {
      transform: scaleY(-1);
    }

    &.triangle-1 {
      bottom: calc($triangle-size * -2);
      right: 0;
    }

    &.triangle-2 {
      top: 0;
      left: calc($triangle-size * -2);
    }
  }

  &.bottom-left .smooth-triangle {
    &::before {
      transform: scaleX(-1);
    }

    &.triangle-1 {
      top: calc($triangle-size * -2);
      left: 0;
    }

    &.triangle-2 {
      bottom: 0;
      right: calc($triangle-size * -2);
    }
  }

  &.top-left .smooth-triangle {
    &::before {
      transform: rotate(180deg);
    }

    &.triangle-1 {
      bottom: calc($triangle-size * -2);
      left: 0;
    }

    &.triangle-2 {
      top: 0;
      right: calc($triangle-size * -2);
    }
  }
}
