// Base styling for the sidenav

.sidenav {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-wrap: nowrap;
  font-size: 0.9rem;

  .sidenav-menu {
    flex-grow: 1;

    .nav {
      flex-direction: column;
      flex-wrap: nowrap;

      .sidenav-menu-heading {
        padding: 1.75rem 1rem 0.75rem;
        font-size: 0.7rem;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 0.05em;
      }

      .nav-link {
        display: flex;
        align-items: center;
        line-height: normal;
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
        position: relative;
        margin-left: 1rem;
        margin-right: 0.75rem;
        margin-top: 0.25rem;
        margin-bottom: 0.25rem;

        .nav-link-icon {
          font-size: 0.9rem;
          padding-right: 0.5rem;
          display: inline-flex;

          .feather {
            width: 1rem;
            height: 1rem;
          }
        }

        &.active {
          font-weight: 600;

          .nav-icon {
            color: $primary;
          }
        }

        .sidenav-collapse-arrow {
          display: inline-block;
          margin-left: auto;
          transition: transform 0.15s ease;
        }

        &.collapsed {
          .sidenav-collapse-arrow {
            transform: rotate(-90deg);
          }
        }

        .badge {
          padding: 0.125rem 0.25rem;
        }
      }

      .sidenav-menu-nested {
        flex-direction: column;
        margin-left: 2rem - $sidenav-nested-border-offest;
        border-left-style: solid;
        border-left-width: thin;
        padding-left: $sidenav-nested-border-offest;
      }
    }
  }
  .sidenav-footer {
    .sidenav-footer-links {
      flex-direction: column;
      flex-wrap: nowrap;
    }

    .sidenav-footer-links,
    .sidenav-footer-user {
      .nav-link {
        display: flex;
        align-items: center;
        line-height: normal;
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
        position: relative;
        margin-left: 1rem;
        margin-right: 1rem;
        margin-top: 0.25rem;
        margin-bottom: 0.25rem;

        .nav-link-icon {
          font-size: 0.9rem;
          padding-right: 0.5rem;
          display: inline-flex;
        }
      }
    }

    .sidenav-footer-user {
      display: flex;
      flex-shrink: 0;
      align-items: center;
      padding-left: 0.75rem;
      height: $footer-base-height;
      cursor: pointer;

      .sidenav-footer-user-menu-button {
        padding-right: 0.75rem;
      }

      .sidenav-footer-user-menu {
        z-index: 1000;
      }

      .sidenav-footer-user-content {
        font-size: 0.9rem;
        width: 100%;

        .sidenav-footer-user-subtitle {
          font-size: 0.75rem;
        }

        .sidenav-footer-user-title {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          width: calc(80%);
          font-weight: 500;
        }
      }
    }
  }
}
