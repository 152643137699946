.depict--CategoryToolbar {
  .depict--CategoryToolbar__left {
    display: flex;
    align-items: center;
    .depict--CategoryToolbar__categoryName,
    .depict--CategoryToolbar__nOfProducts {
      font-size: 1.313rem;
    }

    .depict--ConfigurationSettings__form {
      font-size: 0.85rem;
    }

    .form-input-spinner {
      float: left;
      display: flex;
      justify-content: center;
      align-content: center;
    }

    .spinner-border {
      width: 0.85rem;
      height: 0.85rem;
      border-width: 0.15rem;
    }
  }

  &__right {
    max-width: 300px;
  }
}
