@import "src/assets/scss/sb-admin-pro/core/variables/_colors.scss";
@import "src/assets/scss/sb-admin-pro/_variables-colors-overrides.scss";
@import "src/assets/scss/sb-admin-pro/_variables-overrides.scss";
@import "src/assets/scss/variables.scss";

.depict--spinLoader {
  display: flex;
  .spinner-border {
    border-color: #fff;
    border-right-color: #fff0;
    width: 1.5rem;
    height: 1.5rem;
  }
}

@media (min-width: $hamburger-menu-breakpoint) {
  .depict--spinLoader {
    position: fixed;
    top: 1.5rem;
    right: 1.5rem;
    z-index: 1040;
    margin-top: 0;
    .spinner-border {
      border-color: $gray-600;
      border-right-color: #fff0;
    }
  }
}
