@import "src/assets/scss/sb-admin-pro/core/variables/_colors.scss";
@import "src/assets/scss/sb-admin-pro/_variables-colors-overrides.scss";
@import "src/assets/scss/sb-admin-pro/_variables-overrides.scss";
@import "src/assets/scss/variables.scss";

.depict--CreateRelationships {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    font-size: 0.688rem;
    color: $gray-600;
  }
  button {
    width: 130px;
  }
}
