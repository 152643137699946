@import "src/assets/scss/sb-admin-pro/core/variables/_colors.scss";
@import "src/assets/scss/sb-admin-pro/_variables-colors-overrides.scss";
@import "src/assets/scss/sb-admin-pro/_variables-overrides.scss";
@import "src/assets/scss/variables.scss";

.depict--ListConfigurations {
  height: 100%;
  flex-direction: column;
  display: flex;
  ul {
    padding-left: 0;
    list-style: none;
    flex: auto;
    height: 100px; //random
    overflow-y: auto;
    -ms-overflow-style: none; // IE 10+
    overflow: -moz-scrollbars-none; // Firefox
    &::-webkit-scrollbar {
      display: none; // Safari and Chrome
    }
    li {
      font-size: 0.75rem;
      padding: 0.75rem 1.25rem;
      cursor: pointer;
      display: flex;
      &:nth-child(odd) {
        background-color: $gray-100;
      }
      &.depict--ListConfigurations__itemSelected {
        background-color: $dpt-color-background-base-pressed;
        font-weight: 600;
      }
      .depict--ListConfigurations__iconRight {
        margin-left: auto;
      }
      .depict--ListConfigurations__name {
        white-space: nowrap;
        word-wrap: break-word;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
}

@media (max-width: map-get($grid-breakpoints-topdown, "lg")) {
  .depict--ListConfigurations {
    ul {
      height: auto;
    }
  }
}
