@import "src/assets/scss/sb-admin-pro/core/variables/_colors.scss";
@import "src/assets/scss/sb-admin-pro/_variables-colors-overrides.scss";
@import "src/assets/scss/sb-admin-pro/_variables-overrides.scss";
@import "src/assets/scss/variables.scss";

@media (min-width: $hamburger-menu-breakpoint) {
  .depict--Help.btn-icon.btn-sm {
    position: fixed;
    right: 1.5rem;
    bottom: 1.5rem;
    color: #fff;
    background-color: black;
    width: 2.5rem !important;
    height: 2.5rem !important;
    z-index: 1;
  }
  .sideoverlayright-toggled {
    .depict--Help.btn-icon.btn-sm {
      display: none;
    }
  }
}
