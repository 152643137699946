@import "src/assets/scss/sb-admin-pro/core/variables/_colors.scss";
@import "src/assets/scss/sb-admin-pro/_variables-colors-overrides.scss";
@import "src/assets/scss/sb-admin-pro/_variables-overrides.scss";
@import "src/assets/scss/variables.scss";

.list-item {
  flex: 1;
  display: flex;
  border-radius: 5px;
  background-color: $gray-100;
  overflow: hidden;
  margin-bottom: 0.625rem;
  min-height: 42px;
  cursor: pointer;
  @extend .noselect;
}

.list-item-selected {
  background-color: $primary-light !important;
  font-weight: 700;
}

.list-img {
  flex-basis: 2.625rem;
  background-color: $gray-200;
  flex-basis: 2.5rem;
  width: 2.625rem;
  min-width: 2.625rem;
  height: 2.625rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: left;
  img {
    object-fit: cover;
    max-width: 100%;
    max-height: 100%;
  }
}

.list-content {
  padding: 0.35rem 0.625rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 1px;
  justify-content: center;
}

.list-title {
  word-break: break-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.list-status {
  font-size: 0.563rem;
  display: flex;
  align-self: center;
}

.list-icon {
  flex-basis: 1.5rem;
  width: 1.5rem;
  min-width: 1.5rem;
  display: flex;
  justify-content: left;
  align-items: center;
  cursor: pointer;
}

.depict--ItemsList {
  display: flex;
  flex-direction: column;
  height: 100%;
  ul {
    list-style: none;
    padding: 0;
    font-size: 0.75rem;
    flex: 1 1 auto;
    overflow-y: auto;
    margin-bottom: 0.625rem;
    height: 100px; //just something
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none; /* Chrome, Safari and Opera */
    }
    .depict--ItemsList__item {
      @extend .list-item;
      &.depict--ItemsList__item__outOfStock,
      &.depict--ItemsList__item__deleted {
        .depict--ItemsList__img {
          padding: 1px;
          background: transparent;
          img {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
          }
        }
        .depict--ItemsList__status {
          color: $warning;
          align-self: flex-start;
        }
      }
      &.depict--ItemsList__item__deleted {
        background-color: lighten(map-get($palette, "depict-red"), 30%);
        .depict--ItemsList__status {
          color: map-get($palette, "depict-red");
        }
      }
      &.depict--ItemsList__item__selected {
        @extend .list-item-selected;
      }
    }
    .depict--ItemsList__img {
      @extend .list-img;
    }
    .depict--ItemsList__content {
      @extend .list-content;
    }
    .depict--ItemsList__status {
      @extend .list-status;
    }
    .depict--ItemsList__title {
      @extend .list-title;
    }
    .depict--ItemsList__icon {
      @extend .list-icon;
    }
  }
  &.depict--ItemsList__dark {
    ul {
      .depict--ItemsList__item {
        background-color: $gray-900;
        color: $gray-100;
        &.depict--ItemsList__item__selected {
          background-color: map-get($palette, "depict-blue") !important;
          font-weight: 700;
          color: #fff;
        }
      }
    }
  }
}

/**
* TODO: temporary disabled, does not play nice with drag n drop
*/
// .depict--lastExpandedPanel {
//   .depict--ItemsList ul {
//     --bs-gutter-x: 1rem;
//     --bs-gutter-y: 0;
//     margin-top: calc(-1 * var(--bs-gutter-y));
//     margin-right: calc(-0.5 * var(--bs-gutter-x));
//     margin-left: calc(-0.5 * var(--bs-gutter-x));
//     flex-wrap: wrap;
//     display: flex;
//     align-content: flex-start;
//     li {
//       width: calc(50% - var(--bs-gutter-x));
//       flex: none;
//       max-width: 100%;
//       margin-right: calc(var(--bs-gutter-x) * 0.5);
//       margin-left: calc(var(--bs-gutter-x) * 0.5);
//     }
//   }
// }

@media (max-width: map-get($grid-breakpoints-topdown, "sm")) {
  .depict--ItemsList {
    ul {
      height: auto;
    }
  }
}

.depict--DragOverlay {
  > li {
    list-style: none;
  }
}

.depict--ItemsList__item.depict--ItemsList__item--DragOverlay {
  @extend .list-item;
  @extend .list-item-selected;
  margin-bottom: 0;
  font-size: 0.75rem;
  @extend .noselect;
  .depict--ItemsList__img {
    @extend .list-img;
  }
  .depict--ItemsList__content {
    @extend .list-content;
  }
  .depict--ItemsList__icon {
    @extend .list-icon;
  }
  .depict--ItemsList__status {
    display: none;
  }
  .depict--ItemsList__title {
    @extend .list-title;
  }
}
