.depict--ConfigurationGroup__section {
  .depict--ConfigurationGroup__body {
    height: 25vh;
    -ms-overflow-style: none;
    overflow-y: auto;
    overflow: -moz-scrollbars-none;
    flex: auto;
    margin: 0;
    &::-webkit-scrollbar {
      display: none; // Safari and Chrome
    }
  }
}

.depict--ConfigurationGroup__item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #FAFAFC;
  padding: 0.75rem;

  .depict--ConfigurationGroupItem__title {
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.depict--ConfigurationGroupItem__options {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  input {
    flex-shrink: 1;
    padding: 0.25rem 0.5rem;
    font-size: 1rem;
    max-width: 50%;
    background-color: #FAFAFC;
    border: none;
    width: auto;
    text-align: center;
  }

  svg {
    min-width: 16px;
    min-height: 16px;
  }
}
