.depict--Filters__section {
  font-size: 0.75rem;
  .depict--Filters__section__Plus {
    display: none;
  }
  .collapsed {
    .depict--Filters__section__Minus {
      display: none;
    }
    .depict--Filters__section__Plus {
      display: inline;
    }
  }
}
