@import "src/assets/scss/sb-admin-pro/core/variables/_colors.scss";
@import "src/assets/scss/sb-admin-pro/_variables-colors-overrides.scss";
@import "src/assets/scss/sb-admin-pro/_variables-overrides.scss";
@import "src/assets/scss/variables.scss";


.depict--ListCategories {
  padding: 0;
  display: flex;
  height: 100%;
  flex-direction: column;
  > ul {
    padding-right: 1.25rem;
    height: 100px;
    -ms-overflow-style: none;
    overflow-y: auto;
    overflow: -moz-scrollbars-none;
    flex: auto;
    margin: 0;
    &::-webkit-scrollbar {
      display: none; // Safari and Chrome
    }
  }
  ul {
    padding-top: 0.625rem;
  }
  &__item {
    padding: 0.625rem 0 0.625rem 1.5rem;
    font-size: 0.75rem;
    > ul {
      display: none;
    }
    &#{&}--Expanded {
      > ul {
        display: block;
      }
    }
    &#{&}--Selected {
      > .depict--ListCategories__content {
        .depict--ListCategories__content__name {
          font-weight: 700;
        }
      }
    }
  }
  &__content {
    display: flex;
    align-content: center;
    &__name {
      flex: 1;
      cursor: pointer;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 100%;
    }
    &__chevron {
      width: 0.625rem;
      display: flex;
      align-items: center;
      margin-right: 0.313rem;
      svg {
        cursor: pointer;
      }
    }
  }
}

@media (max-width: map-get($grid-breakpoints-topdown, "lg")) {
  .depict--ListCategories {
    max-width: 100%;
    > ul {
      height: auto;
    }
  }
}
