@import "src/assets/scss/sb-admin-pro/core/variables/_colors.scss";
@import "src/assets/scss/sb-admin-pro/_variables-colors-overrides.scss";
@import "src/assets/scss/sb-admin-pro/_variables-overrides.scss";
@import "src/assets/scss/variables.scss";

/* MAINLY BUTTON GROUPS */

.apply-hw-accelerate {
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);

  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;

  -webkit-perspective: 1000;
  -moz-perspective: 1000;
  -ms-perspective: 1000;
  perspective: 1000;

  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.depict--filterBar {
  .btn-check:checked + .btn-outline-primary,
  .btn-check:active + .btn-outline-primary,
  .btn-outline-primary:active,
  .btn-outline-primary.active,
  .btn-outline-primary.dropdown-toggle.show {
    background-color: $primary-light;
    border-color: $primary-light;
  }
}

$transition-duration: 0.2s;

.depict--filterCompareSection,
.depict--filterPeriodSection {
  .btn-group,
  button {
    transition-property: border-radius;
    transition-duration: $transition-duration;
    transition-timing-function: ease-in-out;
    transition-delay: 0s;
  }
  .depict--sectionMiddle {
    height: 0;
    margin-top: -2px;
    overflow: hidden;
    transition-property: height;
    transition-timing-function: ease-in-out;
    transition-duration: $transition-duration;
    transition-delay: $transition-duration;
    .depict--datepickerWrapper {
      padding: 10px;
      .input-group {
        background: $gray-300;
        .input-group-text {
          background: transparent;
        }
      }
      .react-datepicker-wrapper {
        width: auto;
        padding: 3px 10px;
        max-width: 150px;
        input {
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          border: 0;
          background: transparent;
          &:focus {
            outline: none;
          }
        }
      }
      .react-datepicker {
        display: flex;
        flex-direction: column-reverse;
        .react-datepicker__navigation {
          top: 22px;
        }
      }
    }
  }
  &.depict--filterPanel__open {
    .depict--sectionTop {
      .bg-primary-light,
      .btn-group {
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }
      .btn-group {
        overflow: hidden;
      }
      .bg-primary-light {
        --webkit-appearance: none; //random thing just to make linter shut up.
        /* border-top-right-radius: 0 !important; */
      }
    }
    .depict--sectionMiddle {
      margin-top: -2px;
      height: 48px;
      > div {
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
      }
    }
  }
  &.depict--filterPanel__allowOverflow {
    .depict--sectionMiddle {
      overflow: visible;
    }
  }
}

@media (max-width: map-get($grid-breakpoints-topdown, "sm")) {
  .depict--filterCompareSection,
  .depict--filterPeriodSection {
    .depict--sectionMiddle {
      .depict--datepickerWrapper {
        .react-datepicker-wrapper {
          max-width: 120px;
        }
      }
    }
  }
}

/* general styling */
.depict--filterBar {
  select,
  button,
  label {
    font-size: 0.75rem;
    color: $black;
  }
  label {
    display: block;
    margin-bottom: 5px;
  }
  button {
    border: 0;
  }
  .form-select {
    height: 2.5rem;
    background-position: right 0.75rem center;
    padding-right: 1.875rem;
    &:focus {
      box-shadow: none;
    }
  }
  .depict--datepickerWrapper {
    input {
      font-size: 0.75rem;
    }
  }
  .depict--filterCompareSection,
  .depict--filterPeriodSection {
    // margin-right: 1rem;
    button {
      padding-left: 15px;
      padding-right: 15px;
      font-size: 11px;
    }
  }
  .btn-group.rounded-pill {
    button {
      margin-top: -2px;
      margin-bottom: -2px;
      &:last-child {
        border-top-right-radius: 1.25rem !important;
      }
      &:hover {
        font-weight: 500;
        color: $black;
      }
      &.btn.btn-disabled {
        text-decoration: line-through;
        opacity: 0.65;
        cursor: default;
        &:focus {
          box-shadow: 0 0 0 0;
        }
        &:hover {
          font-weight: normal;
        }
      }
    }
  }
  .form-check.form-switch {
    height: 40px;
    display: flex;
    align-items: center;
    .form-check-label {
      margin-left: 10px;
      margin-bottom: 0;
    }
    .form-check-input {
      margin-top: 0;
    }
  }
}

/**
* 
*/
@media (max-width: 1439px) {
  .depict--filterPeriodSection {
    .depict--sectionTop {
      .btn-group {
        button:nth-child(2) {
          display: none;
        }
      }
    }
  }
}

@media (min-width: 1500px) {
  .depict--filterPeriodSection,
  .depict--filterCompareSection {
    margin-right: 1.5rem;
  }
}

.depict--calendarBasedWrapper {
  height: 35px;
}
