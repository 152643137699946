$modal-min-width: min(960px, 100%);

.category-config-modal {
  min-width: $modal-min-width;
}

.category-config-modal-content {
  min-width: $modal-min-width;
  max-height: 90vh;
}
