.depict--AxisTooltipsWrapper {
  width: 100%;
  height: 100%;
  position: relative;
  .depict--AxisTooltip {
    position: absolute;
    z-index: 1;
    &.depict--AxisTooltip__left {
      height: 100%;
      width: 40px;
    }
    &.depict--AxisTooltip__bottom {
      width: 100%;
      height: 15px;
      bottom: 0;
    }
  }
}
