@import "src/assets/scss/sb-admin-pro/core/variables/_colors.scss";
@import "src/assets/scss/sb-admin-pro/_variables-colors-overrides.scss";
@import "src/assets/scss/sb-admin-pro/_variables-overrides.scss";
@import "src/assets/scss/variables.scss";

.depict--tablePanel {
  overflow: hidden;
  .depict--dropdownToggle {
    position: absolute;
    cursor: pointer;
    height: 24px;
    width: 24px;
    left: calc(50% - 12px);
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &.depict--tablePanel__hasMoreThan3Rows {
    .depict--tablePanelContainer {
      transition-property: max-height;
      transition-duration: 0.2s;
      transition-timing-function: ease-in-out;
      transition-delay: 0s;
    }
  }
  &.depict--tablePanel__hasMoreThan3Rows:not(.depict--tablePanel__isExpanded) {
    .depict--tablePanelContainer {
      max-height: 10rem !important;
      .table {
        tbody {
          tr:nth-child(3) {
            opacity: 0.5;
          }
        }
      }
    }
  }
  &.depict--tablePanel__hasMoreThan3Rows.depict--tablePanel__isExpanded {
    .depict--dropdownToggle {
      transform: rotate(180deg);
      bottom: 6px;
    }
  }
}

@media (max-width: map-get($grid-breakpoints-topdown, "sm")) {
  .depict--sectionTable {
    .card-body {
      padding: 1rem;
    }
  }
}

@media (max-width: map-get($grid-breakpoints-topdown, "lg")) {
  .depict--tablePanel {
    th[data-content="period"],
    td[data-content="period"] {
      display: none;
    }
  }
}
