@import "src/assets/scss/sb-admin-pro/core/variables/_colors.scss";
@import "src/assets/scss/sb-admin-pro/_variables-colors-overrides.scss";
@import "src/assets/scss/sb-admin-pro/_variables-overrides.scss";
@import "src/assets/scss/variables.scss";

.depict--marketSelector {
  width: 120px;
  max-width: 120px;
  button {
    display: block;
    width: 100%;
    cursor: default;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    &:hover {
      color: $black;
    }
  }
  div[class*="indicatorContainer"] {
    svg {
      visibility: hidden;
    }
  }
}

@media (min-width: 1500px) {
  .depict--marketSelector,
  .depict--filterPeriodSection,
  .depict--filterCompareSection {
    margin-right: 1.5rem;
  }
}
