@import "src/assets/scss/sb-admin-pro/core/variables/_colors.scss";
@import "src/assets/scss/sb-admin-pro/_variables-colors-overrides.scss";
@import "src/assets/scss/sb-admin-pro/_variables-overrides.scss";
@import "src/assets/scss/variables.scss";

.depict--miniGraphs {
  .depict--miniGraph {
    .depict-miniGraphIcon {
      position: absolute;
      right: calc($grid-gutter-width);
      bottom: 0;
      cursor: pointer;
      * {
        color: $gray-600;
      }
      .depict-miniGraphIcon__minimize {
        display: none;
      }
    }
    &.depict--miniGraph__expanded {
      .depict-miniGraphIcon__minimize {
        display: block;
      }
      .depict-miniGraphIcon__expand {
        display: none;
      }
    }
  }
}

@media (max-width: map-get($grid-breakpoints-topdown, "md")) {
  .depict--miniGraphs {
    .depict--miniGraph {
      .depict-miniGraphIcon {
        display: none;
      }
    }
  }
}
