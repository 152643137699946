//
// Variable overrides
//

// This file is intentionally blank
// Use this file to override any core SCSS variables
$input-border-radius: 1.5rem;
$input-border-radius-sm: 1.5rem;
$input-border-radius-lg: 1.5rem;
$input-border-width: 0;
$input-padding-y: 0.5rem;
$grid-gutter-width: 0.75rem;

//TABLE
$table-cell-padding-x: 0.5rem;
$table-color: $black;
$table-striped-bg-factor: 0.1;
$table-striped-bg: rgba($primary, $table-striped-bg-factor);
$table-border-factor: 0.1;
$table-border-width: 1px;
$table-border-color: rgba($primary, $table-border-factor);

//TOOLTIPS
$tooltip-font-size: 0.875rem;

/**
* Custom variable.
*/
