@import "src/assets/scss/sb-admin-pro/core/variables/_colors.scss";
@import "src/assets/scss/sb-admin-pro/_variables-colors-overrides.scss";
@import "src/assets/scss/sb-admin-pro/_variables-overrides.scss";
@import "src/assets/scss/variables.scss";

.depict--SelectedProductsSidebar {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.depict--SelectedProductsSidebar__header {
  p {
    margin: 0;
    padding: 0.5rem 1rem;
    cursor: pointer;
  }
  .selected {
    background-color: $primary-light;
    font-weight: 500;
  }
}

.depict--SelectedProductsSidebar__body {
  flex-grow: 1;

  .depict--SelectedProductsSidebar__item {
    background-color: $primary-light;
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
    margin-bottom: 0.5rem;
  }
}

.depict--SelectedProductsSidebar__footer {
  display: grid;
  grid-template-columns: 4fr 6fr;
  text-align: center;
  .depict--SelectedProductsSidebar__cancel {
    border: 1px solid $gray-800;
    padding: 0.5rem 1rem;
    border-radius: 18px;
    margin-bottom: 0.5rem;
    cursor: pointer;
  }

  .depict--SelectedProductsSidebar__apply {
    flex-grow: 2;
    background-color: $primary-light;
    padding: 0.5rem 1rem;
    border-radius: 18px;
    margin-bottom: 0.5rem;
    cursor: pointer;
  }
}
