@import "src/assets/scss/sb-admin-pro/core/variables/_colors.scss";
@import "src/assets/scss/sb-admin-pro/_variables-colors-overrides.scss";
@import "src/assets/scss/sb-admin-pro/_variables-overrides.scss";
@import "src/assets/scss/variables.scss";

.modal-backdrop {
  z-index: 1071; //one more of popover
}

.depict--Modal {
  z-index: 1071; //one more of popover
  .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    background-size: 0.75rem;
    margin: 1.5rem;
  }

  .modal-title {
    width: 100%;
    font-size: 1.5rem;
    font-weight: 700;
  }

  .modal-header {
    padding: 0;
    margin-bottom: 1.5rem;
    border-bottom: none;
  }

  .modal-footer {
    padding: 0;
    margin-top: 1.5rem;
    justify-content: right;
    border-top: none;
  }

  .modal-content {
    padding: 2rem;
    border-radius: 1rem;
  }

  .modal-body {
    padding: 0;
    font-size: 1rem;
    color: $dpt-color-text-icon-neutral-default;
  }

  .modal-dialog {
    transition: width 0.5s ease-in-out;
  }

  &.--wide {
    .modal-dialog {
      max-width: 1250px;
      width: 75%;
    }
  }

  &.--narrow {
    .modal-dialog {
      max-width: 500px;
    }
  }

  &.--tiny {
    .modal-dialog {
      max-width: 250px;
    }
  }
}

@media (min-width: map-get($grid-breakpoints, "sm")) {
  .depict--Modal {
    .modal-dialog {
      max-width: 610px;
    }
  }
}

.depict--ModalHelp {
  .modal-title {
    font-size: 1.5rem;
  }
}
