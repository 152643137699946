@import "src/assets/scss/sb-admin-pro/core/variables/_colors.scss";
@import "src/assets/scss/sb-admin-pro/_variables-colors-overrides.scss";
@import "src/assets/scss/sb-admin-pro/_variables-overrides.scss";
@import "src/assets/scss/variables.scss";

.depict--ConfigureConfiguration__relationship__from,
.depict--ConfigureConfiguration__relationship__to {
  .depict--ItemsList__item.depict--ItemsList__item__type__group {
    background-color: $gray-900;
    color: $gray-100;
    &.depict--ItemsList__item__selected {
      background-color: map-get($palette, "depict-blue");
      font-weight: 700;
      color: #fff;
    }
  }
}

.depict--ConfigureConfiguration__relationship__from,
.depict--ConfigureConfiguration__relationship__to {
  .depict--CardCollapsible {
    .card-header {
      font-size: 0.875rem;
      border-width: 2px;
    }
  }
}

@media (max-width: map-get($grid-breakpoints-topdown, "sm")) {
  .depict--ConfigureConfiguration__relationship__from,
  .depict--ConfigureConfiguration__relationship__to {
    .depict--CardCollapsible {
      .card-header {
        border-width: 1px;
      }
    }
  }
}

.depict--ConfigureConfiguration__relationship {
  min-width: 0; //fixed bad flex behaviour (https://stackoverflow.com/questions/38223879/white-space-nowrap-breaks-flexbox-layout)
  > div {
    min-width: 0; //fixed bad flex behaviour
    > div {
      min-width: 0; //fixed bad flex behaviour
    }
  }
  > div {
    .depict--CardCollapsible {
      border-color: map-get($palette, "depict-mint");
      .card-header {
        border-bottom-color: map-get($palette, "depict-mint");
      }
    }
  }
  > div:first-child {
    .depict--CardCollapsible {
      border-color: $primary-light;
      .card-header {
        border-bottom-color: $primary-light;
      }
    }
  }
  > div:last-child {
    .depict--CardCollapsible {
      border-color: map-get($palette, "depict-pink");
      .card-header {
        border-bottom-color: map-get($palette, "depict-pink");
      }
    }
  }
  .depict--ConfigureConfiguration__relationship__isDisabled {
    opacity: 0.3;
  }
}

.depict--ConfigureConfiguration__relationship {
  .depict--ItemsList {
    ul {
      min-height: 188px;
    }
  }
}

@media (max-width: map-get($grid-breakpoints-topdown, "lg")) {
  .depict--ConfigureConfiguration__relationship {
    min-height: 550px;
  }
}

@media (max-width: map-get($grid-breakpoints-topdown, "md")) {
  .depict--ConfigureConfiguration__relationship {
    align-content: flex-start;
    > * {
      width: 100%;
      flex: none;
      margin-bottom: 1rem;
    }
    .depict--ItemsList {
      ul {
        height: auto;
        max-height: calc(100vh - 300px);
        min-height: 0;
      }
    }
  }
}

.depict--PanelTitle__icon {
  margin-left: 0.625rem;
  margin-right: 0.625rem;
  cursor: pointer;
}

.depict--CardCollapsible.depict--CardCollapsible__vertical:not(.depict--CardCollapsible__show) {
  .depict--PanelTitle__icon {
    display: none;
  }
}

.depict--setPanel__backgroundIcon {
  position: absolute;
  z-index: 0;
  color: $gray-200;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
