@import "src/assets/scss/sb-admin-pro/core/variables/_colors.scss";
@import "src/assets/scss/sb-admin-pro/_variables-colors-overrides.scss";
@import "src/assets/scss/sb-admin-pro/_variables-overrides.scss";
@import "src/assets/scss/variables.scss";

.depict--sectionRevenue {
  h3 {
    font-size: 1rem;
    color: $gray-600;
  }
}

@media (max-width: map-get($grid-breakpoints-topdown, "sm")) {
  .depict--sectionRevenue {
    .card-body {
      padding: 1rem;
    }
  }
}

.depict--NoRevenueTooltip {
  position: absolute;
  right: 1.35rem;
  top: 1.35rem;
  cursor: pointer;
  width: auto;
  height: auto;
}
