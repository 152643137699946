@import "src/assets/scss/variables.scss";

.depict--Search {
  .gradient-bg {
    background: linear-gradient(10deg, #e5e6fb 30%, #f0e7ff 100%);
  }

  .new-keyword-input {
    border: 1px solid lightgrey;
    border-radius: 0.25rem;
    height: 24px;
    padding: 1px 8px 1px 8px;
    color: #333335;
    width: 100px;
    margin-right: 0.5em;
  }

  .new-keyword-input:focus {
    outline: 1px solid #3018c1;
  }
}
