@import "src/assets/scss/sb-admin-pro/core/variables/_colors.scss";
@import "src/assets/scss/sb-admin-pro/_variables-colors-overrides.scss";
@import "src/assets/scss/sb-admin-pro/_variables-overrides.scss";
@import "src/assets/scss/variables.scss";

.depict--hightlightCard {
  h3 {
    font-size: 0.75rem;
    margin-bottom: 0.75rem;
    + div {
      font-size: 1.5rem;
    }
  }
}

@media (max-width: map-get($grid-breakpoints-topdown, "sm")) {
  .depict--hightlightCard {
    .card-body {
      padding: 1rem;
    }
    h3 {
      font-size: 1rem;
    }
  }
}
