@import "src/assets/scss/variables.scss";

.depict--CategoryBreadcrumb {
  font-size: 0.875rem;

  .target a {
    color: $dpt-color-text-icon-base-default !important;
    &:hover {
      text-decoration: none !important;
      cursor: auto !important;
    }
  }
  .breadcrumb-item + .breadcrumb-item::before {
    content: "/" !important;
    color: $dpt-color-text-icon-base-default !important;
    padding-right: 0.25rem;
  }
  .breadcrumb-item + .breadcrumb-item {
    padding-left: 0.25rem;
  }
  .breadcrumb-item > a {
    color: $dpt-color-text-icon-subtle-default;
  }
  .breadcrumb-item:first-child > a {
    color: $dpt-color-text-icon-base-default;
  }
  ol {
    margin-bottom: 0!important;
    align-items: center;
  }
}