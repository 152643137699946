@import "src/assets/scss/variables";

@mixin PairBadge($border-radius) {
  .depict--PairBadge {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    background: $dpt-pairing-color;
    border-radius: $border-radius;
    font-size: 1rem;
    font-weight: 600;
    padding: 2px 6px 4px;
    color: white;
    box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.15);
    svg {
      margin-bottom: -1px;
      margin-left: 2px;
    }
  }
}
