@import "src/assets/scss/sb-admin-pro/core/variables/_colors.scss";
@import "src/assets/scss/sb-admin-pro/_variables-colors-overrides.scss";
@import "src/assets/scss/sb-admin-pro/_variables-overrides.scss";
@import "src/assets/scss/variables.scss";
@import "src/assets/scss/depict/_variables.scss";
@import "src/assets/scss/depict/components/index.scss";
@import "src/assets/scss/depict/sections/index.scss";

/**
* Font face
* https://developer.mozilla.org/en-US/docs/Web/CSS/font-weight#common_weight_name_mapping
*/

@font-face {
  font-family: "Inter";
  src: url("/src/assets/fonts/inter/Inter-Regular.ttf");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("/src/assets/fonts/inter/Inter-Medium.ttf");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("/src/assets/fonts/inter/Inter-Bold.ttf");
  font-weight: 700;
  font-style: normal;
}

html {
  height: auto;
}

#root {
  // min-height: 100vh;
  height: 100%;
}

// Fixes autoscroll with dnd
html {
  scroll-behavior: auto !important;
}

body {
  margin: 0;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol", "Noto Color Emoji";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/**
* Utilities
*/

.bg-primary-light {
  background-color: $primary-light !important;
}
.border-primary-light {
  border-color: $dpt-color-border-subtle-default !important;
}

.rounded-pill {
  border-radius: 1.25rem !important;
}

.depict--CardContent__zeroPad {
  margin: -1.25rem -1.25rem 0 -1.25rem;
  display: flex;
  flex-direction: column;
  flex: 1;
}

/**
* Forms
*/

.form-check-input,
.form-select {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}
input.form-control,
input.form-control:focus {
  color: $black;
}

.input-group.depict--InputGroupFocused {
  input:focus + .input-group-text {
    background: $primary-light;
  }
}

.form-switch {
  .form-check-input:focus {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%2815, 15, 15, 0.25%29'/%3e%3c/svg%3e");
    border-color: #0f0f0f40;
    box-shadow: none;
  }
}

/**
* Table
*/
.table {
  font-size: 0.875rem;
  > :not(:first-child) {
    border-top-width: 0;
  }
  th {
    font-weight: normal;
  }
  thead {
    th {
      color: $gray-600;
      font-size: 0.75rem;
    }
  }
  td {
    white-space: nowrap;
  }
}

/**
* TOOLTIPS
*/

*[data-bs-toggle="tooltip"] {
  cursor: default;
}

/**
* Cards
*/
.card:not([class*="bg-"]) .card-header {
  color: $black;
}

.card.card-header-actions.depict--CardActionDropdown {
  .card-header {
    height: 0;
    padding: 0;
    border: 0;
    .dropdown {
      margin-left: auto;
      margin-top: 1.4rem;
      margin-right: 0.2rem;
      .dropdown-item {
        font-size: 0.688rem;
      }
    }
  }
}

/**
* Grid
*/
@media (min-width: map-get($grid-breakpoints, "xxl")) {
  .row {
    margin-right: calc(-0.5 * $grid-gutter-width-xxl);
    margin-left: calc(-0.5 * $grid-gutter-width-xxl);
  }
  .row > * {
    padding-left: calc($grid-gutter-width-xxl * 0.5);
    padding-right: calc($grid-gutter-width-xxl * 0.5);
  }
  .container,
  .container-fluid,
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm {
    padding-left: calc($grid-gutter-width-xxl * 0.5);
    padding-right: calc($grid-gutter-width-xxl * 0.5);
  }
}
/* ANIMATION */

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }
}

.animated--fade-out {
  animation-name: fadeOut;
  animation-duration: 600ms;
  animation-timing-function: opacity cubic-bezier(0, 1, 0.4, 1);
}

.react-datepicker-popper {
  z-index: 10 !important;
}

/* table double striped */
.table-striped-double > tbody > tr:nth-of-type(4n + 1) > *,
.table-striped-double > tbody > tr:nth-of-type(4n + 2) > * {
  background-color: $table-striped-bg;
}

/* DEPICT SDK */
.depict.plp .filters .clear-all-filters {
  position: sticky;
  bottom: 0;
}
