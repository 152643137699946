@use "@depict-ai/plp-styling" with (
  $page-background-color: #f2f2f4 // for slider button fade into filter background
);
@include plp-styling.default-theme("all");
@import "./depict/main";
@import "./variables.scss";
@import "./typography.scss";
@import "react-toastify/scss/main.scss";

.form-control {
  border: 1px solid $dpt-color-border-subtle-default;
  border-radius: 0.35rem;
  color: var(--bs-body-color);
}

// Badges
.badge.bg-success {
  color: $dpt-color-text-icon-success-default;
  background-color: $dpt-color-background-success-default;
}

// Secondary Button
.btn-secondary {
  color: $dpt-color-text-icon-neutral-default;
  background-color: $dpt-color-background-neutral-default;
  border-color: $dpt-color-background-neutral-default;
}

.btn-secondary:hover {
  color: $dpt-color-text-icon-neutral-hover;
  background-color: $dpt-color-background-neutral-hover;
  border-color: $dpt-color-background-neutral-hover;
}

.btn-secondary:focus {
  color: $dpt-color-text-icon-neutral-default;
  background-color: $dpt-color-background-neutral-default;
  border-color: $dpt-color-background-neutral-default;
  box-shadow: 0 0 0 0.15rem $dpt-color-border-neutral-default;
}

// Primary Button
.btn-primary {
  color: $dpt-color-text-icon-inverse-default;
  background-color: $dpt-color-background-inverse-default;
  border-color: $dpt-color-background-inverse-default;
}

.btn-primary:hover {
  color: $dpt-color-text-icon-inverse-hover;
  background-color: $dpt-color-background-inverse-hover;
  border-color: $dpt-color-background-inverse-hover;
}

.btn-primary:focus {
  color: $dpt-color-text-icon-inverse-default;
  background-color: $dpt-color-background-inverse-default;
  border-color: $dpt-color-background-inverse-default;
  box-shadow: 0 0 0 0.15rem $dpt-color-border-accent-default;
}

// info Button
.btn-info {
  color: $dpt-color-text-icon-accent-default;
  background-color: $dpt-color-background-accent-default;
  border-color: $dpt-color-background-accent-default;
}

.btn-info:hover {
  color: $dpt-color-text-icon-accent-hover;
  background-color: $dpt-color-background-accent-hover;
  border-color: $dpt-color-background-accent-hover;
}

.btn-info:focus {
  color: $dpt-color-text-icon-accent-default;
  background-color: $dpt-color-background-accent-default;
  border-color: $dpt-color-background-accent-default;
  box-shadow: 0 0 0 0.15rem $dpt-color-border-accent-default;
}

// danger Button
.btn-danger {
  color: $dpt-color-text-icon-danger-default;
  background-color: $dpt-color-background-danger-default;
  border-color: $dpt-color-background-danger-default;
}

.btn-danger:hover {
  color: $dpt-color-text-icon-danger-hover;
  background-color: $dpt-color-background-danger-hover;
  border-color: $dpt-color-background-danger-hover;
}

.btn-danger:focus {
  color: $dpt-color-text-icon-danger-default;
  background-color: $dpt-color-background-danger-default;
  border-color: $dpt-color-background-danger-default;
  box-shadow: 0 0 0 0.15rem $dpt-color-border-danger-default;
}

// Dropdowns

dropdown-item:hover {
  background-color: $dpt-color-background-base-default;
}

dropdown-item:active {
  background-color: $dpt-color-background-base-default;
}

.headline-100-light {
  @include apply-text-style($headline-100-light);
}

.headline-100-heavy {
  @include apply-text-style($headline-100-heavy);
}

.headline-200-light {
  @include apply-text-style($headline-200-light);
}

.headline-200-heavy {
  @include apply-text-style($headline-200-heavy);
}

.headline-300-light {
  @include apply-text-style($headline-300-light);
}

.headline-300-heavy {
  @include apply-text-style($headline-300-heavy);
}

// Fancy purple border
.fancy-border {
  $beautiful-purple: rgba(123, 81, 175, 0.25);
  box-shadow: 0px 4px 30px $beautiful-purple;
  border-radius: 8px;
  border: 2px solid $beautiful-purple;
}

/* Missing BS padding class for 12px or 0.75rem */
.p2-5 {
  padding: 0.75rem;
}

.px-2-5 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.py-2-5 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.ps-2-5 {
  padding-left: 0.75rem;
}

.pe-2-5 {
  padding-right: 0.75rem;
}

.pt-2-5 {
  padding-top: 0.75rem;
}

.pb-2-5 {
  padding-bottom: 0.75rem;
}

.p4-5 {
  padding: 2rem;
}

.px-4-5 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-4-5 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.ps-4-5 {
  padding-left: 2rem;
}

.pe-4-5 {
  padding-right: 2rem;
}

.pt-4-5 {
  padding-top: 2rem;
}

.pb-4-5 {
  padding-bottom: 2rem;
}

/* Missing BS margin class for 12px or 0.75rem */
.m2-5 {
  margin: 0.75rem;
}

.mx-2-5 {
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}

.my-2-5 {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

.ms-2-5 {
  margin-left: 0.75rem;
}

.me-2-5 {
  margin-right: 0.75rem;
}

.mt-2-5 {
  margin-top: 0.75rem;
}

.mb-2-5 {
  margin-bottom: 0.75rem;
}

.m4-5 {
  margin: 2rem;
}

.mx-4-5 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.my-4-5 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.ms-4-5 {
  margin-left: 2rem;
}

.me-4-5 {
  margin-right: 2rem;
}

.mt-4-5 {
  margin-top: 2rem;
}

.mb-4-5 {
  margin-bottom: 2rem;
}

.w-33 {
  width: 33%;
}

.depict--placeholder {
  overflow: hidden;
  position: relative;
  display: inline-block;

  // Animation
  animation: 1.5s infinite linear depict-placeholder;
  background: #dddddd; // fallback and dark color
  // https://source.chromium.org/chromium/chromium/src/+/main:third_party/blink/renderer/core/style/computed_style.cc;l=1146?q=CreatesStackingContext
  // https://linear.app/depictai/issue/FRO-268/bok-question-from-them-on-errors
  // https://web.dev/stick-to-compositor-only-properties-and-manage-layer-count/
  will-change: mask, -webkit-mask;
  mask-position-x: 125%;
  mask-position-y: center;
  mask-size: 300% 100%;
  mask-image: linear-gradient(-60deg, rgb(0, 0, 0) 30%, rgba(0, 0, 0, 0.333), rgb(0, 0, 0) 70%);
  -webkit-mask: linear-gradient(-60deg, #000 30%, #0005, #000 70%) 125% / 300% 100%;
  // https://stackoverflow.com/a/69970936
}

@keyframes depict-placeholder {
  00% {
    mask-position-x: 125%;
    -webkit-mask-position-x: 125%;
  }
  10% {
    mask-position-x: 125%;
    -webkit-mask-position-x: 125%;
  }
  90% {
    mask-position-x: -25%;
    -webkit-mask-position-x: -25%;
  }
  100% {
    mask-position-x: -25%;
    -webkit-mask-position-x: -25%;
  }
}
