@import "src/assets/scss/sb-admin-pro/core/variables/_colors.scss";
@import "src/assets/scss/sb-admin-pro/_variables-colors-overrides.scss";
@import "src/assets/scss/sb-admin-pro/_variables-overrides.scss";
@import "src/assets/scss/variables.scss";

@keyframes animation__fade_in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.depict--miniGraphs {
  .depict--miniGraph {
    position: relative;
    .depict--cardMetric {
      cursor: pointer;
      canvas {
        max-width: 100% !important;
      }
      .depict--cardMetricLeft {
        padding-right: 8px;
        margin: 0;
        .depict--cardMetricLeftInner {
          min-width: 104px;
          justify-content: center;
        }
      }
    }
    &.depict--fadeIn {
      animation-duration: 0.3s;
      animation-name: animation__fade_in;
    }
  }
  &.depict-miniGraphs__row3 {
    //3 mini graphs on one row
    .depict--miniGraph:not(.depict--miniGraph__expanded) {
      width: 50%;
    }
  }
  &.depict-miniGraphs__row4 {
    //4 mini graphs on one row
    .depict--miniGraph:not(.depict--miniGraph__expanded) {
      width: 33.3333%;
    }
  }
  .depict--miniGraph:not(.depict--miniGraph__expanded) {
    order: 2;
    flex: none;
    transition-property: none;
    .depict--cardMetric {
      h5 {
        font-size: 0.75rem;
      }
      .card-body {
        padding-right: calc($grid-gutter-width / 2);
        //padding-top: calc($grid-gutter-width / 2);
        word-break: break-all;
      }
      .chart-area {
        height: 5.625rem;
      }
      canvas {
        cursor: pointer !important;
      }
      .depict--cardMetricLeft {
        padding-right: 8px;
        margin: 0;
        .depict--cardMetricLeftInner {
          min-width: 104px;
          justify-content: flex-end;
        }
      }
      .depict--cardMetricIconWrapper {
        top: 0.625rem;
        left: 1.35rem;
      }
      .spinner-border {
        width: 1rem;
        height: 1rem;
        border-width: 0.2em;
      }
    }
  }
  &.depict--miniGraphs__allMinimized {
    &.depict-miniGraphs__row3 {
      .depict--miniGraph {
        width: 33.3333%;
      }
    }
    &.depict-miniGraphs__row4 {
      .depict--miniGraph {
        width: 25%;
      }
    }
  }
  .depict--miniGraphDivider {
    flex-basis: 100%;
    height: 0px;
    order: 1;
  }
}

@media (max-width: map-get($grid-breakpoints-topdown, "md")) {
  .depict--miniGraphs .depict--miniGraph {
    width: 100% !important;
  }
}

@media (max-width: map-get($grid-breakpoints-topdown, "sm")) {
  .depict--miniGraphs .depict--miniGraph {
    .depict--cardMetric {
      cursor: default;
    }
  }
}
